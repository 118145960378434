import * as React from 'react';

function SvgArrowUp(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 19 11"
            width="1.73em"
            {...props}
        >
            <path
                d="M18.7 10.4a.99.99 0 01-1.4 0l-6.846-6.845a1.067 1.067 0 00-1.509 0L2.1 10.4A.99.99 0 11.7 9L8.818.882a1.247 1.247 0 011.764 0L18.7 9a.99.99 0 010 1.4z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgArrowUp;
