import * as React from 'react';

function SvgLogout(props) {
    return (
        <svg
            width="1.18em"
            height="1em"
            fill="none"
            viewBox="0 0 40 34"
            {...props}
        >
            <path
                d="M30.707 10.802c0-1.084 1.3-1.636 2.08-.885l5.713 5.506a2 2 0 01-.005 2.885l-5.706 5.458c-.783.749-2.082.194-2.082-.89V20.46a.662.662 0 00-.662-.662h-10.28a2.963 2.963 0 110-5.926h10.28a.662.662 0 00.662-.662zm-7.514 15.857c1.142-.753 2.684-.74 3.647.23 1.011 1.022.99 2.694-.167 3.546-2.93 2.157-6.052 3.235-9.367 3.235-4.87 0-8.973-1.61-12.306-4.832s-5-7.244-5-12.07c0-3.03.763-5.836 2.29-8.418a16.806 16.806 0 016.178-6.11C11.061.745 13.872 0 16.902 0c3.38 0 6.615 1.112 9.709 3.335 1.16.834 1.2 2.499.195 3.514-.944.952-2.456.965-3.569.218-2.003-1.344-4.104-2.017-6.301-2.017-3.344 0-6.167 1.168-8.468 3.502-2.3 2.335-3.451 5.185-3.451 8.552 0 3.143 1.184 5.848 3.552 8.115 2.368 2.267 5.146 3.4 8.333 3.4 2.212 0 4.308-.653 6.291-1.96z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgLogout;
