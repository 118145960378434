import * as React from 'react';

function SvgUnlike(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 16 17"
            width="0.94em"
            {...props}
        >
            <path
                d="M12.78 9.082V.522l-.007 8.56zM2.014 10.848h6.288l-.192 3.968a.71.71 0 00.714.744.886.886 0 00.844-.646l1.67-6.05V1.96H3.351a1.137 1.137 0 00-.534 1.582l.278.508-.438.38a1.135 1.135 0 00-.392.86c0 .194.046.378.138.546l.278.508-.438.38a1.135 1.135 0 00-.392.86c0 .194.046.378.138.546l.28.51-.438.38a1.135 1.135 0 00-.392.86c0 .382.22.75.576.968z"
                fill="#fff"
            />
            <path
                d="M16 8.44V1.16a.64.64 0 00-.64-.64h-1.3v8.56h1.3a.64.64 0 00.64-.64zM.522 8.326A2.574 2.574 0 000 9.88c0 .898.502 1.75 1.31 2.22.208.122.445.186.686.186h4.798l-.12 2.458c-.03.594.18 1.158.59 1.588A2.129 2.129 0 008.822 17c1.04 0 1.96-.7 2.236-1.702l1.716-6.216.006-8.56H3.338c-.186 0-.364.036-.53.108A2.572 2.572 0 001.35 3.738a2.574 2.574 0 00-.414 2.294 2.574 2.574 0 00-.414 2.294zm1.31.694l.438-.38-.28-.51a1.124 1.124 0 01-.138-.546c0-.33.142-.644.392-.86l.438-.38-.278-.508a1.124 1.124 0 01-.138-.546c0-.33.142-.644.392-.86l.438-.38-.278-.508a1.137 1.137 0 01.534-1.582h7.988v6.904l-1.67 6.05a.886.886 0 01-.844.646.71.71 0 01-.714-.744l.192-3.968H2.016c-.356-.218-.576-.586-.576-.968 0-.33.142-.644.392-.86z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgUnlike;
