export const GUTTER = '1.5rem';

function availableSpace(gutter, totalColumns) {
    return `100% - (${gutter} * (${totalColumns} - 1))`;
}

function availableWidth(columns, totalColumns, gutter) {
    return `((${availableSpace(
        gutter,
        totalColumns
    )}) / ${totalColumns}) * ${columns}`;
}

function gutterSpace(columns, gutter) {
    return `(${columns - 1}) * ${gutter}`;
}

function columnWidth(columns, totalColumns, gutter) {
    return `(${availableWidth(columns, totalColumns, gutter)}) + (${gutterSpace(
        columns,
        gutter
    )})`;
}

/**
 * Calculates the width of a column based on a few parameters.
 *
 * @param {Number} columns - The target number of columns.
 * @param {Number} columnsPerRow - The total number of columns per row on the grid.
 * @param {String|Number} gutter - The gutter size.
 * @param {Number} [embeddedGutters=0] - A multiplier of gutters to include on the width.
 * @returns {String} - Something.
 */
export const width = (
    columns,
    columnsPerRow,
    embeddedGutters = 0,
    gutter = GUTTER
) => `
    width: calc((${columnWidth(
        columns,
        columnsPerRow,
        gutter
    )}) + (${embeddedGutters} * ${gutter}));
`;

export const offset = (
    columns,
    columnsPerRow,
    offsetTailGutter = 1,
    flow = 'left',
    gutter = GUTTER
) => `
    margin-${flow}: calc(${columnWidth(
    columns,
    columnsPerRow,
    gutter
)} + (${gutter} * ${offsetTailGutter}));
`;

export const tailGutter = (tailGutter, gutter, flow = 'left') =>
    flow === 'left'
        ? `&:not(:last-of-type) { margin-right: calc(${tailGutter} * ${gutter}); }`
        : `&:not(:first-of-type) { margin-left: calc(${tailGutter} * ${gutter}); }`;
