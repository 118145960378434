export const LOAD_OPENED_ACTIONS_START =
    'OPENEDACTIONS/LOAD_OPENED_ACTIONS_START';
export const LOAD_OPENED_ACTIONS_SUCCESS =
    'OPENEDACTIONS/LOAD_OPENED_ACTIONS_SUCCESS';
export const LOAD_OPENED_ACTIONS_ERROR =
    'OPENEDACTIONS/LOAD_OPENED_ACTIONS_ERROR';

export const DONE_ACTION_START = 'OPENEDACTIONS/DONE_ACTION_START';
export const DONE_ACTION_SUCCESS = 'OPENEDACTIONS/DONE_ACTION_SUCCESS';
export const DONE_ACTION_ERROR = 'OPENEDACTIONS/DONE_ACTION_ERROR';
