import * as React from 'react';

function SvgArrow(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 19 26"
            width="0.73em"
            {...props}
        >
            <path
                d="M.92 25.358a1.255 1.255 0 001.291-.08l16.25-11.25a1.25 1.25 0 000-2.056L2.211.723A1.25 1.25 0 00.25 1.75v22.5a1.25 1.25 0 00.67 1.108z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgArrow;
