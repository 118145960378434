import * as React from 'react';

function SvgRetro(props) {
    return (
        <svg
            fill="none"
            width="1.29em"
            height="1em"
            viewBox="0 0 40 31"
            {...props}
        >
            <path
                d="M3.362 30.37h25.215c1.16 0 2.068-.59 2.673-1.484l8.473-12.773c.37-.574.37-1.299 0-1.873L31.25 1.485C30.645.607 29.62 0 28.46 0H3.362C1.512 0 0 1.519 0 3.374v23.622c0 1.856 1.513 3.374 3.362 3.374zm21.853-17.716c1.396 0 2.522 1.13 2.522 2.531a2.522 2.522 0 11-5.043 0c0-1.4 1.126-2.53 2.521-2.53zm-8.405 0c1.395 0 2.522 1.13 2.522 2.531a2.522 2.522 0 11-5.043 0c0-1.4 1.126-2.53 2.521-2.53zm-8.405 0c1.395 0 2.522 1.13 2.522 2.531a2.522 2.522 0 11-5.043 0c0-1.4 1.126-2.53 2.521-2.53z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgRetro;
