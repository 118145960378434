import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Global } from '@emotion/core';

import global from './components/themes/global';
import Home from './pages/home/Home';
import BasePage from './pages/basePage/BasePage';
import Board from './pages/board/Board';
import NotFound from './pages/404/NotFound';
import History from './components/historyAccount/History';
import OpenedActions from './components/openedActions/OpenedActions';

function App() {
    return (
        <>
            <Global styles={global} />
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/home">
                        <BasePage>
                            <History />
                        </BasePage>
                    </Route>
                    <Route exact path={['/boards', '/boards/:id']}>
                        <BasePage>
                            <Board />
                        </BasePage>
                    </Route>
                    <Route exact path={'/openedactions'}>
                        <BasePage>
                            <OpenedActions />
                        </BasePage>
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;
