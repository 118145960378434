import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    selectors as boardSelectors,
    actions as boardActions
} from '../../state/board';
import { selectors as accountSelectors } from '../../state/account';

import {
    mainYellow,
    white,
    lightGrey,
    middleGrey,
    darkGrey
} from '../themes/colors';
import { mainFont } from '../themes/typography';
import Button from '../button/Button';
import Card from '../card/Card';
import SvgLike from '../assets/SvgLike';
import SvgUnlike from '../assets/SvgUnlike';

const StyledGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: ${middleGrey};
    border: ${({ hasCardsMerged }) =>
        hasCardsMerged ? `solid 0.4rem ${mainYellow}` : 'none'};
    border-radius: 0.8rem;
`;

const StyledButton = styled(Button)`
    color: ${darkGrey};
    font-weight: 200;
    font-size: 1.8rem;

    &:hover {
        color: ${mainYellow};
    }

    &:first-of-type {
        margin-top: auto;
        margin-right: auto;
    }
`;

const StyledJoinButton = styled(Button)`
    margin-left: auto;
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;

    &:hover {
        color: ${mainYellow};
    }
`;

const Checkbox = styled('input')`
    width: 1.8rem;
    height: 1.8rem;
    background-color: ${lightGrey};
    cursor: pointer;

    &:checked {
        background-color: ${mainYellow};
    }
`;

const ToolBar = styled.div`
    display: flex;
    flex-direction: row;
`;

const Votes = styled.span`
    margin-left: 0.5rem;
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
`;

function Group({
    allUserVotesInBoard,
    userVotes,
    boardId,
    boardStatus,
    cards,
    disabled,
    groupId,
    lock,
    merged,
    numVotes,
    onMergeCheck,
    onMergeClick,
    onRemoveDraft,
    onUnMergeClick,
    sectionId
}) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const board = useSelector(state => boardSelectors.getBoardById(state, id));
    const userId = useSelector(accountSelectors.getUserId);

    if (
        cards.every(card => !card.publish && card.owner?._id !== userId) &&
        !cards.some(card => !!card.draftId)
    ) {
        return null;
    }

    const canRemoveVotes = cards?.[0].group?.userVotes > 0;

    const canMergeCard = boardStatus !== 'finished' && !lock;

    const hasCards = cards && cards[0].publish && !cards[0].draftId;

    const showNumberOfVotes = board?.status !== 'retro';

    const numberOfVotes = cards?.[0].group?.totalVotes || 0;

    return (
        <StyledGroup hasCardsMerged={cards.length > 1}>
            {cards?.map(card => (
                <Card
                    key={card._id || card.draftId}
                    disabled={lock}
                    published={card.publish}
                    text={card.text}
                    id={card._id}
                    draftId={card.draftId}
                    boardId={boardId}
                    sectionId={sectionId}
                    owner={card.owner}
                    status={boardStatus}
                    merged={card.merge}
                    onUnMergeClick={onUnMergeClick}
                    onRemoveDraft={onRemoveDraft}
                    lock={lock}
                    userVotes={card.group?.userVotes}
                    totalVotes={card.group?.totalVotes || 0}
                />
            ))}
            {hasCards && (
                <ToolBar>
                    {showNumberOfVotes && (
                        <Votes>
                            {numberOfVotes}
                            {board?.status !== 'voting' && ' Votes'}
                        </Votes>
                    )}

                    {!disabled && board?.status === 'voting' && (
                        <ToolBar>
                            <StyledButton
                                aria-label="Vote"
                                title="Vote"
                                onClick={async () => {
                                    await dispatch(
                                        boardActions.voteGroup(
                                            groupId,
                                            boardId,
                                            sectionId
                                        )
                                    );
                                }}
                                disabled={allUserVotesInBoard === numVotes}
                            >
                                <SvgLike />
                            </StyledButton>

                            {canRemoveVotes && (
                                <>
                                    <StyledButton
                                        aria-label="Remove vote"
                                        title="Remove vote"
                                        onClick={async () => {
                                            await dispatch(
                                                boardActions.unvoteGroup(
                                                    groupId,
                                                    boardId,
                                                    sectionId
                                                )
                                            );
                                        }}
                                    >
                                        <SvgUnlike />
                                    </StyledButton>
                                    {cards?.[0].group?.userVotes > 1 && (
                                        <Votes>{userVotes}</Votes>
                                    )}
                                </>
                            )}
                        </ToolBar>
                    )}
                    {canMergeCard && (
                        <>
                            <StyledJoinButton
                                onClick={() => onMergeClick(cards[0]._id)}
                            >
                                join
                            </StyledJoinButton>
                            <Checkbox
                                id={groupId}
                                type="checkbox"
                                onChange={() => {
                                    onMergeCheck(cards);
                                }}
                                checked={merged}
                            />
                        </>
                    )}
                </ToolBar>
            )}
        </StyledGroup>
    );
}

Group.propTypes = {
    allUserVotesInBoard: PropTypes.number,
    userVotes: PropTypes.number,
    boardId: PropTypes.string,
    boardStatus: PropTypes.string,
    cards: PropTypes.array,
    disabled: PropTypes.bool,
    groupId: PropTypes.string,
    lock: PropTypes.bool,
    merged: PropTypes.bool,
    numVotes: PropTypes.number,
    onMergeCheck: PropTypes.func,
    onMergeClick: PropTypes.func,
    onRemoveDraft: PropTypes.func,
    onUnMergeClick: PropTypes.func,
    sectionId: PropTypes.string
};

export default Group;
