import * as React from 'react';

function SvgActions(props) {
    return (
        <svg
            fill="none"
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            {...props}
        >
            <g fill="currentColor">
                <path d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0zm0 35.042c-8.295 0-15.042-6.748-15.042-15.042 0-8.295 6.747-15.044 15.042-15.044S35.043 11.705 35.043 20c0 8.294-6.748 15.042-15.043 15.042z" />
                <path d="M20.027 11.238c-.69 0-1.286-.212-1.779-.636-.493-.425-.739-1.028-.739-1.806 0-.706.251-1.287.757-1.742a2.544 2.544 0 011.76-.686c.645 0 1.219.206 1.717.62.496.414.748 1.017.748 1.808 0 .767-.245 1.363-.731 1.798a2.53 2.53 0 01-1.733.644zm2.464 5.147v14.397c0 .998-.24 1.752-.713 2.263-.472.51-1.076.766-1.806.766s-1.325-.26-1.78-.784c-.457-.524-.683-1.272-.683-2.245v-14.25c0-.985.226-1.728.683-2.226.455-.5 1.05-.746 1.78-.746s1.333.246 1.806.746c.473.497.713 1.193.713 2.08z" />
            </g>
        </svg>
    );
}

export default SvgActions;
