import * as React from 'react';

function SvgAddButton(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 28 28"
            width="1em"
            {...props}
        >
            <path
                d="M28 14c0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0s14 6.268 14 14z"
                fill="#949598"
            />
            <path d="M16 12V8h-4v4H8v4h4v4h4v-4h4v-4z" fill="currentColor" />
        </svg>
    );
}

export default SvgAddButton;
