import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import {
    selectors as boardSelectors,
    actions as boardActions
} from '../../state/board';
import { selectors as accountSelectors } from '../../state/account';

import { mainYellow, darkGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';
import Button from '../button/Button';
import Input from '../input/Input';
import SvgArrowUp from '../assets/SvgArrowUp';
import SvgArrowDown from '../assets/SvgArrowDown';
import SvgCancel from '../assets/SvgCancel';
import SvgNext from '../assets/SvgNext';
import SvgPrevious from '../assets/SvgPrevious';
import SvgUnlockSetupBar from '../assets/SvgUnlockSetupBar';
import SvgLockSetupBar from '../assets/SvgLockSetupBar';
import client from '../../client';

const BOARD_STATUS = ['retro', 'voting', 'actions'];

const TIME_TO_DISAPPEAR = 2000;

const StyledButton = styled(Button)`
    margin-top: auto;
    margin-bottom: auto;
    padding: ${({ prepare, status }) =>
        prepare || status === 'finished' ? '0rem' : '1rem'};
    font-size: 2.2rem;

    &:hover {
        color: ${mainYellow};
    }

    & > svg {
        font-size: 1.5rem;
    }
`;

const Status = styled.p`
    margin-top: auto;
    margin-bottom: auto;
    color: ${darkGrey};
    font-weight: bold;
    font-size: 3rem;
    font-family: ${mainFont};

    &:first-letter {
        text-transform: uppercase;
    }
`;

const Label = styled.label`
    margin: ${({ prepare }) =>
        prepare ? 'auto 0 auto 2rem;' : 'auto 2rem auto 2rem;'};
    color: ${darkGrey};
    font-weight: bold;
    font-size: 2rem;
    font-family: ${mainFont};
`;

const LabelInput = styled.label`
    margin-bottom: 0.5rem;
    color: ${darkGrey};
    font-weight: bold;
    font-size: 1.8rem;
    font-family: ${mainFont};
`;

const LabelInputDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 2rem;
`;

const InputDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const InformationDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`;

const VotesButtonDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto 2rem auto 0;
`;

const Votes = styled.input`
    width: 3rem;
    color: ${darkGrey};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
    text-align: center;
    background-color: transparent;
    border: none;
    resize: none;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        margin: 0;
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
    }
    /* Firefox */
    &[type='number'] {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -moz-appearance: textfield;
    }
`;

const StyledLink = styled(Link)`
    position: absolute;
    top: 0;
    right: 0;
    height: 1.6rem;
    padding: 1rem;
    color: ${darkGrey};
    font-size: 1.6rem;
    font-family: ${mainFont};
    text-decoration: none;
    &:hover {
        color: ${mainYellow};
    }
`;

const Form = styled.form`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
`;

const StatusActionDiv = styled.div`
    display: flex;
    flex-direction: ${({ status }) =>
        status === 'finished' ? 'column' : 'row'};
    margin-top: ${({ status }) => (status === 'finished' ? '2rem' : '0')};
    margin-right: 3.5rem;
`;

const StyledLockButton = styled(Button)`
    margin-right: 1.5rem;
    font-size: 3.5rem;

    &:hover {
        color: ${mainYellow};
    }
`;

const SetupBar = () => {
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [copied, setCopied] = useState(false);
    const board = useSelector(state => boardSelectors.getBoardById(state, id));
    const userId = useSelector(accountSelectors.getUserId);

    const { register, handleSubmit, setValue, reset, getValues } = useForm({
        defaultValues: {
            title: board?.title || '',
            team: board?.team || '',
            numVotes: board?.numVotes || 0,
            status: board?.status || 'prepare',
            lock: board?.lock || false,
            sections: board?.sections || ''
        }
    });

    const onSubmit = async values => {
        if (board?._id) {
            return;
        }
        const newBoard = await dispatch(
            boardActions.createBoard({
                title: values.title,
                team: values.team,
                numVotes: values.numVotes
            })
        );
        if (newBoard) {
            history.push(`/boards/${newBoard._id}`);
        }
    };

    useEffect(() => {
        if (board) {
            setValue([
                { title: board.title },
                { team: board.team },
                { numVotes: board.numVotes }
            ]);
        } else {
            reset({ title: '', team: '', numVotes: 0 });
        }
    }, [setValue, reset, board]);

    const increment = () => {
        const currentNumVotes = getValues('numVotes');
        setValue('numVotes', parseInt(currentNumVotes, 10) + 1);
        const newNumVotes = getValues('numVotes');
        dispatch(boardActions.editBoard(id, { numVotes: newNumVotes }));
    };

    const decrement = () => {
        const currentNumVotes = getValues('numVotes');
        setValue('numVotes', parseInt(currentNumVotes, 10) - 1);
        const newNumVotes = getValues('numVotes');
        dispatch(boardActions.editBoard(id, { numVotes: newNumVotes }));
    };

    if (id && !board) {
        return null;
    }

    const nextStatus = BOARD_STATUS[BOARD_STATUS.indexOf(board?.status) + 1];

    const previousStatus =
        BOARD_STATUS[BOARD_STATUS.indexOf(board?.status) - 1];

    const isFinished = board?._id && board?.status === 'finished';

    const showAllowedVotes = board?._id && board?.status === 'retro';

    const showVoteStats = board?._id && board?.status !== 'retro';

    const canEditNumVotes =
        !board ||
        (board?._id &&
            board?.status === 'retro' &&
            board?.owner._id === userId);

    const copyBoard = async () => {
        const response = await client.get(`/boards/${id}/export?format=txt`);
        await navigator.clipboard.writeText(response.data);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, TIME_TO_DISAPPEAR);
    };

    const playersName = board?.guests
        .map(guest => guest.displayName)
        .join(', ');

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InformationDiv>
                <LabelInputDiv>
                    <LabelInput>Title</LabelInput>
                    <LabelInput>Team</LabelInput>
                    {board && <LabelInput>Players</LabelInput>}
                </LabelInputDiv>
                <InputDiv>
                    <Input
                        autoFocus={!id}
                        name="title"
                        type="text"
                        placeholder="Title..."
                        disabled={board?.lock}
                        ref={register}
                        onBlur={async () => {
                            if (board?._id) {
                                const title = getValues('title');
                                await dispatch(
                                    boardActions.editBoard(id, { title })
                                );
                            }
                        }}
                    />
                    <Input
                        name="team"
                        type="text"
                        placeholder="Team..."
                        disabled={board?.lock}
                        ref={register}
                        onBlur={async () => {
                            if (board?._id) {
                                const team = getValues('team');
                                await dispatch(
                                    boardActions.editBoard(id, { team })
                                );
                            }
                        }}
                    />
                    {board && (
                        <Input
                            name="guests"
                            type="text"
                            placeholder="Players..."
                            disabled
                            defaultValue={playersName}
                        />
                    )}
                </InputDiv>
            </InformationDiv>
            {canEditNumVotes && (
                <>
                    <Label prepare>Votes:</Label>
                    <VotesButtonDiv>
                        <StyledButton
                            title="More Votes"
                            type="button"
                            onClick={increment}
                            prepare
                        >
                            <SvgArrowUp />
                        </StyledButton>
                        <Votes
                            min="0"
                            name="numVotes"
                            type="number"
                            ref={register}
                        />
                        <StyledButton
                            title="Less Votes"
                            type="button"
                            onClick={decrement}
                            prepare
                        >
                            <SvgArrowDown />
                        </StyledButton>
                    </VotesButtonDiv>
                </>
            )}
            {showVoteStats && (
                <Label>
                    Votes: {board?.votedInBoard}/{board?.totalVotesInBoard}
                </Label>
            )}
            {showAllowedVotes && (
                <Label>Votes: {board?.totalVotesInBoard}</Label>
            )}
            {(board?.status === 'actions' || board?.status === 'finished') &&
                board?.owner._id === userId &&
                (board?.lock ? (
                    <StyledLockButton
                        type="button"
                        onClick={async () => {
                            await dispatch(boardActions.unlockBoard(id));
                        }}
                        aria-label="Unlock board"
                    >
                        <SvgLockSetupBar />
                    </StyledLockButton>
                ) : (
                    <StyledLockButton
                        type="button"
                        onClick={async () => {
                            await dispatch(boardActions.lockBoard(id));
                        }}
                        aria-label="Lock board"
                    >
                        <SvgUnlockSetupBar />
                    </StyledLockButton>
                ))}
            <StatusActionDiv status={board?.status}>
                {board?._id && previousStatus && (
                    <StyledButton
                        aria-label="Previous"
                        title="Previous"
                        type="button"
                        onClick={async () => {
                            await dispatch(
                                boardActions.editBoard(id, {
                                    status: previousStatus
                                })
                            );
                        }}
                    >
                        <SvgPrevious />
                    </StyledButton>
                )}
                <Status>{board?.status || 'prepare'}</Status>
                {!board && (
                    <StyledButton
                        aria-label="Start a retro"
                        title="Start"
                        type="submit"
                    >
                        <SvgNext />
                    </StyledButton>
                )}

                {board?._id && board?.status !== 'finished' && nextStatus && (
                    <StyledButton
                        aria-label="Next"
                        title="Next"
                        type="button"
                        onClick={async () => {
                            await dispatch(
                                boardActions.editBoard(id, {
                                    status: nextStatus
                                })
                            );
                        }}
                    >
                        <SvgNext />
                    </StyledButton>
                )}
                {isFinished && (
                    <StyledButton status={board?.status} onClick={copyBoard}>
                        {copied ? 'copied' : 'copy'}
                    </StyledButton>
                )}
            </StatusActionDiv>
            <StyledLink aria-label="Cancel" title="Cancel" to="/home">
                <SvgCancel />
            </StyledLink>
        </Form>
    );
};

export default SetupBar;
