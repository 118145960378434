import * as React from 'react';

function SvgLike(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 16 17"
            width="0.94em"
            {...props}
        >
            <path
                d="M3.22 8.438v8.56l.007-8.56zm10.765-1.766H7.697l.192-3.968a.71.71 0 00-.714-.744.886.886 0 00-.844.646l-1.67 6.05v6.904h7.988a1.137 1.137 0 00.534-1.582l-.278-.508.438-.38a1.136 1.136 0 00.254-1.406l-.278-.508.438-.38a1.136 1.136 0 00.254-1.406l-.28-.51.438-.38a1.136 1.136 0 00.392-.86c0-.382-.22-.75-.576-.968z"
                fill="#fff"
            />
            <path
                d="M0 9.08v7.28c0 .354.286.64.64.64h1.3V8.44H.64a.64.64 0 00-.64.64zm15.478.114c.336-.444.522-.988.522-1.554 0-.898-.502-1.75-1.31-2.22a1.353 1.353 0 00-.686-.186H9.206l.12-2.458a2.125 2.125 0 00-.59-1.588A2.128 2.128 0 007.178.52c-1.04 0-1.96.7-2.236 1.702L3.226 8.438l-.006 8.56h9.442c.186 0 .364-.036.53-.108a2.572 2.572 0 001.458-3.108 2.574 2.574 0 00.414-2.294 2.574 2.574 0 00.414-2.294zm-1.31-.694l-.438.38.28.51c.092.167.14.355.138.546 0 .33-.142.644-.392.86l-.438.38.278.508c.092.167.14.355.138.546 0 .33-.142.644-.392.86l-.438.38.278.508a1.137 1.137 0 01-.534 1.582H4.66V8.656l1.67-6.05a.886.886 0 01.844-.646.71.71 0 01.714.744l-.192 3.968h6.288c.356.218.576.586.576.968 0 .33-.142.644-.392.86z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgLike;
