import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { actions } from '../../state/board';

import { mainYellow, lightGrey, darkGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';
import { HEADER_HEIGHT } from '../themes/variables';
import SvgAdd from '../assets/SvgAdd';
import Button from '../button/Button';
import Group from '../group/Group';

const AddCardButton = styled(Button)`
    margin-left: auto;
    padding: 0.5rem;
    font-size: 2rem;

    &:hover {
        color: ${mainYellow};
    }
`;

const StyledRetroSection = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
    height: calc(100% - ${HEADER_HEIGHT});
    margin: 9.4rem 0.5rem 0 0.5rem;
    overflow-y: auto;
    background-color: ${lightGrey};
    border-radius: 0.8rem;
`;

const Title = styled.p`
    margin: 0.5rem;
    margin-right: 5rem;
    color: ${darkGrey};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
`;

const ToolsDiv = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0.5rem;
    background: linear-gradient(
        180deg,
        rgba(228, 228, 229, 1) 80%,
        rgba(228, 228, 229, 0) 100%
    );
    border-radius: 0.8rem;
`;

function Section({
    allUserVotesInBoard,
    boardId,
    boardStatus,
    cards,
    lock,
    numVotes,
    sectionId,
    title
}) {
    const dispatch = useDispatch();
    const [cardsToMerge, setCardsToMerge] = useState([]);
    const [draftCards, setDraftCards] = useState([]);

    const addDraftCard = () => {
        setDraftCards(prevCards => [
            ...prevCards,
            {
                draftId: uuidv4()
            }
        ]);
    };

    const removeDraftCard = draftId => {
        setDraftCards(prevCards =>
            prevCards.filter(card => card.draftId !== draftId)
        );
    };

    const groupedCards = cards?.reduce((res, curr, index) => {
        const id = curr.group?._id || curr.draftId;

        if (res[id]) {
            res[id].push(curr);
        } else {
            Object.assign(res, { [id]: [curr] });
        }

        return res;
    }, {});

    const handleSubmitCards = async cardId => {
        setCardsToMerge([]);

        const card = cards.find(card => card._id === cardId);
        const cardsMerge = cardsToMerge.filter(card => card !== cardId);

        await dispatch(
            actions.mergeCards(
                card.group._id,
                { cardId: cardsMerge },
                boardId,
                sectionId
            )
        );
    };

    const handleMergeCards = cards => {
        setCardsToMerge(prevCards =>
            cards
                .filter(card => !prevCards.includes(card._id))
                .map(card => card._id)
                .concat(
                    prevCards.filter(
                        prevCard => !cards.some(card => card._id === prevCard)
                    )
                )
        );
    };

    const handleUnMergeCards = async cardId => {
        const card = cards.find(card => card._id === cardId);

        await dispatch(
            actions.unmergeCards(
                card.group._id,
                { cardId: [cardId] },
                boardId,
                sectionId
            )
        );
    };

    const canCreateCards = boardId && boardStatus !== 'actions' && !lock;

    return (
        <StyledRetroSection>
            <ToolsDiv>
                <Title>{title}</Title>
                {canCreateCards && (
                    <AddCardButton
                        title="New Card"
                        onClick={addDraftCard}
                        aria-label="Add new card"
                    >
                        <SvgAdd />
                    </AddCardButton>
                )}
            </ToolsDiv>

            {draftCards &&
                draftCards.map(draft => (
                    <Group
                        key={draft.draftId}
                        cards={[draft]}
                        boardId={boardId}
                        sectionId={sectionId}
                        onRemoveDraft={removeDraftCard}
                        lock={lock}
                    />
                ))}

            {groupedCards &&
                Object.entries(groupedCards).map(([id, cards]) => (
                    <Group
                        key={id}
                        groupId={id}
                        cards={cards}
                        lock={lock}
                        boardId={boardId}
                        sectionId={sectionId}
                        boardStatus={boardStatus}
                        disabled={lock}
                        merged={cardsToMerge.includes(cards[0]._id)}
                        onMergeClick={handleSubmitCards}
                        onMergeCheck={handleMergeCards}
                        onUnMergeClick={handleUnMergeCards}
                        numVotes={numVotes}
                        allUserVotesInBoard={allUserVotesInBoard}
                        userVotes={cards[0].group.userVotes}
                    />
                ))}
        </StyledRetroSection>
    );
}

Section.propTypes = {
    allUserVotesInBoard: PropTypes.number,
    boardId: PropTypes.string,
    boardStatus: PropTypes.string,
    cards: PropTypes.array,
    lock: PropTypes.bool,
    numVotes: PropTypes.number,
    sectionId: PropTypes.string,
    title: PropTypes.string
};

export default Section;
