import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';

import { selectors, actions } from '../../state/openedActions';

import { HEADER_HEIGHT } from '../themes/variables';
import { lightGrey, mainYellow, darkGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';
import Button from '../button/Button';
import Loading from '../loading/Loading';
import OpenedAction from '../openedAction/OpenedAction';

const Main = styled.main`
    display: flex;
    flex-direction: column;
    height: calc(100% - ${HEADER_HEIGHT} - 1.8rem);
    margin: calc(${HEADER_HEIGHT} + 0.8rem) 1rem 1rem 11rem;
`;

const StyledViewButton = styled(Button)`
    margin-left: auto;
    padding: 1rem;
    font-size: 2.2rem;

    &:hover {
        color: ${mainYellow};
    }
`;

const StyledSection = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 1rem);
    margin: 0.5rem 0.5rem;
    background-color: ${lightGrey};
    border-radius: 0.8rem;
`;

const Title = styled.p`
    position: sticky;
    top: 0;
    width: 100%;
    margin: 1rem 1rem 0rem 0;
    padding: 0 0 1rem 1rem;
    overflow: hidden;
    color: ${darkGrey};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
    white-space: nowrap;
    text-overflow: ellipsis;
    background: linear-gradient(
        180deg,
        rgba(228, 228, 229, 1) 80%,
        rgba(228, 228, 229, 0) 100%
    );

    &:first-letter {
        text-transform: uppercase;
    }
`;

const OpenedActions = () => {
    const dispatch = useDispatch();
    const openedActions = useSelector(selectors.getOpenedActions);

    const isLoadingOpenedActions = useSelector(
        selectors.isOpenedActionsLoading
    );
    const [showClosed, setShowClosed] = useState(false);
    const onClick = () => setShowClosed(!showClosed);

    useEffect(() => {
        dispatch(actions.loadOpenedActions());
    }, [dispatch]);

    if (isLoadingOpenedActions || !openedActions) {
        return <Loading />;
    }

    openedActions.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
    });

    const openedActionsByTeam = openedActions.reduce(
        (teamsSoFar, { team, ...board }) => {
            if (!teamsSoFar[team]) {
                teamsSoFar[team] = {
                    name: team,
                    boards: [board]
                };
            } else {
                teamsSoFar[team].boards.push(board);
            }

            return teamsSoFar;
        },
        {}
    );

    return (
        <Main>
            {openedActions.some(board =>
                board.actions?.some(act => !act.opened)
            ) && (
                <StyledViewButton onClick={onClick} title="View">
                    {showClosed ? 'hide closed' : 'show closed'}
                </StyledViewButton>
            )}
            {Object.values(openedActionsByTeam)
                .filter(team =>
                    team.boards.some(board =>
                        board.actions?.some(act => showClosed || act.opened)
                    )
                )
                .map(team => (
                    <StyledSection key={team.name}>
                        <Title>{team.name}</Title>
                        {team.boards.map(board =>
                            board.actions
                                .filter(act => showClosed || act.opened)
                                .sort((a, b) => {
                                    if (!a.opened && !b.opened) {
                                        return (
                                            new Date(b.closedDate) -
                                            new Date(a.closedDate)
                                        );
                                    } else if (a.opened && b.opened) {
                                        return (
                                            new Date(a.createdAt) -
                                            new Date(b.createdAt)
                                        );
                                    } else {
                                        return b.opened - a.opened;
                                    }
                                })
                                .map(action => (
                                    <OpenedAction
                                        key={action._id}
                                        opened={action.opened}
                                        createdAt={action.createdAt}
                                        closedDate={action.closedDate}
                                        id={action._id}
                                        text={action.text}
                                        taskOwner={action.taskOwner}
                                        owner={action.owner}
                                        boardTitle={board.title}
                                        boardId={board.boardId}
                                    />
                                ))
                        )}
                    </StyledSection>
                ))}
        </Main>
    );
};

export default OpenedActions;
