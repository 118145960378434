import client from '../../client';

import * as actionTypes from './actionTypes';

export const loadBoards = () => async dispatch => {
    dispatch({
        type: actionTypes.LOAD_BOARD_START
    });

    try {
        const result = await client.get('/boards');
        dispatch({
            type: actionTypes.LOAD_BOARD_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_BOARD_ERROR });
    }
};

export const loadBoardById = id => async dispatch => {
    dispatch({
        type: actionTypes.LOAD_BOARD_ID_START
    });

    try {
        const result = await client.get(`/boards/${id}`);
        dispatch({
            type: actionTypes.LOAD_BOARD_ID_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_BOARD_ID_ERROR });
    }
};

export const createBoard = body => async dispatch => {
    dispatch({
        type: actionTypes.CREATE_BOARD_START
    });

    try {
        const result = await client.post(`/boards`, body);
        dispatch({
            type: actionTypes.CREATE_BOARD_SUCCESS,
            payload: result.data
        });
        return result.data;
    } catch (error) {
        dispatch({ type: actionTypes.CREATE_BOARD_ERROR });
    }
};

export const editBoard = (id, body) => async dispatch => {
    dispatch({
        type: actionTypes.EDIT_BOARD_START
    });

    try {
        const result = await client.patch(`/boards/${id}`, body);
        dispatch({
            type: actionTypes.EDIT_BOARD_SUCCESS,
            payload: result.data
        });
        return result.data;
    } catch (error) {
        dispatch({ type: actionTypes.EDIT_BOARD_ERROR });
    }
};

export const deleteBoard = boardId => async dispatch => {
    dispatch({
        type: actionTypes.DELETE_BOARD_START
    });

    try {
        await client.delete(`/boards/${boardId}`);
        dispatch({
            type: actionTypes.DELETE_BOARD_SUCCESS,
            payload: { boardId }
        });
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_BOARD_ERROR });
    }
};

export const createCard = (boardId, draftId, body) => async dispatch => {
    dispatch({
        type: actionTypes.CREATE_CARD_START
    });

    try {
        const result = await client.post(`/boards/${boardId}/cards`, body);
        dispatch({
            type: actionTypes.CREATE_CARD_SUCCESS,
            payload: { result: result.data, sectionId: body.sectionId, draftId }
        });
        return result.data;
    } catch (error) {
        dispatch({ type: actionTypes.CREATE_CARD_ERROR });
    }
};

export const editCard = (boardId, id, body) => async dispatch => {
    dispatch({
        type: actionTypes.EDIT_CARD_START
    });

    try {
        const result = await client.patch(
            `/boards/${boardId}/cards/${id}`,
            body
        );
        dispatch({
            type: actionTypes.EDIT_CARD_SUCCESS,
            payload: { result: result.data, sectionId: body.sectionId, boardId }
        });
        return result.data;
    } catch (error) {
        dispatch({ type: actionTypes.EDIT_CARD_ERROR });
    }
};

export const deleteCard = (boardId, sectionId, id) => async dispatch => {
    dispatch({
        type: actionTypes.DELETE_CARD_START
    });

    try {
        const result = await client.delete(`/boards/${boardId}/cards/${id}`);

        dispatch({
            type: actionTypes.DELETE_CARD_SUCCESS,
            payload: { result: result.data, boardId, sectionId, id }
        });
        return result.data;
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_CARD_ERROR });
    }
};

export const mergeCards = (
    groupId,
    cardId,
    boardId,
    sectionId
) => async dispatch => {
    dispatch({
        type: actionTypes.MERGE_CARD_START
    });

    try {
        const result = await client.put(`/groups/${groupId}/merge`, cardId);
        dispatch({
            type: actionTypes.MERGE_CARD_SUCCESS,
            payload: { result: result.data, boardId, sectionId }
        });
    } catch (error) {
        dispatch({ type: actionTypes.MERGE_CARD_ERROR });
    }
};

export const unmergeCards = (
    groupId,
    cardId,
    boardId,
    sectionId
) => async dispatch => {
    dispatch({
        type: actionTypes.UNMERGE_CARD_START
    });

    try {
        const result = await client.put(`/groups/${groupId}/unmerge`, cardId);
        dispatch({
            type: actionTypes.UNMERGE_CARD_SUCCESS,
            payload: { result: result.data, boardId, sectionId }
        });
    } catch (error) {
        dispatch({ type: actionTypes.UNMERGE_CARD_ERROR });
    }
};

export const voteGroup = (groupId, boardId) => async dispatch => {
    dispatch({
        type: actionTypes.VOTE_GROUP_START
    });

    try {
        const result = await client.put(
            `boards/${boardId}/groups/${groupId}/vote`
        );
        dispatch({
            type: actionTypes.VOTE_GROUP_SUCCESS,
            payload: { result: result.data, boardId }
        });
    } catch (error) {
        dispatch({ type: actionTypes.VOTE_GROUP_ERROR });
    }
};

export const unvoteGroup = (groupId, boardId) => async dispatch => {
    dispatch({
        type: actionTypes.UNVOTE_GROUP_START
    });

    try {
        const result = await client.put(
            `boards/${boardId}/groups/${groupId}/unvote`
        );
        dispatch({
            type: actionTypes.UNVOTE_GROUP_SUCCESS,
            payload: { result: result.data, boardId }
        });
    } catch (error) {
        dispatch({ type: actionTypes.UNVOTE_GROUP_ERROR });
    }
};

export const lockBoard = boardId => async dispatch => {
    dispatch({
        type: actionTypes.LOCK_BOARD_START
    });

    try {
        const result = await client.put(`boards/${boardId}/lock`);
        dispatch({
            type: actionTypes.LOCK_BOARD_SUCCESS,
            payload: { result: result.data, boardId }
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOCK_BOARD_ERROR });
    }
};

export const unlockBoard = boardId => async dispatch => {
    dispatch({
        type: actionTypes.UNLOCK_BOARD_START
    });

    try {
        const result = await client.put(`boards/${boardId}/unlock`);
        dispatch({
            type: actionTypes.UNLOCK_BOARD_SUCCESS,
            payload: { result: result.data, boardId }
        });
    } catch (error) {
        dispatch({ type: actionTypes.UNLOCK_BOARD_ERROR });
    }
};

export const updateBoardSocket = board => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_BOARD_SOCKET,
        payload: { board }
    });
};

export const deleteBoardSocket = board => async dispatch => {
    dispatch({
        type: actionTypes.DELETE_BOARD_SOCKET,
        payload: { board }
    });
};

export const updateCardSocket = (data, boardId) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_CARD_SOCKET,
        payload: { data, boardId }
    });
};

export const deleteCardSocket = (card, boardId) => async dispatch => {
    dispatch({
        type: actionTypes.DELETE_CARD_SOCKET,
        payload: { card, boardId }
    });
};

export const updateGroupSocket = (group, boardId) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_GROUP_SOCKET,
        payload: { group, boardId }
    });
};

export const mergeCardSocket = (cards, boardId) => async dispatch => {
    dispatch({
        type: actionTypes.MERGE_CARD_SOCKET,
        payload: { cards, boardId }
    });
};

export const unmergeCardSocket = (cards, boardId) => async dispatch => {
    dispatch({
        type: actionTypes.UNMERGE_CARD_SOCKET,
        payload: { cards, boardId }
    });
};

export const groupVotesSocket = board => async dispatch => {
    dispatch({
        type: actionTypes.GROUP_VOTES_SOCKET,
        payload: { board }
    });
};

export const createAction = (boardId, draftId, body) => async dispatch => {
    dispatch({
        type: actionTypes.CREATE_ACTION_START
    });

    try {
        const result = await client.post(`/boards/${boardId}/actions`, body);
        dispatch({
            type: actionTypes.CREATE_ACTION_SUCCESS,
            payload: { result: result.data, draftId, boardId }
        });
        return result.data;
    } catch (error) {
        dispatch({ type: actionTypes.CREATE_ACTION_ERROR });
    }
};

export const editAction = (boardId, id, body) => async dispatch => {
    dispatch({
        type: actionTypes.EDIT_ACTION_START
    });

    try {
        const result = await client.patch(
            `/boards/${boardId}/actions/${id}`,
            body
        );
        dispatch({
            type: actionTypes.EDIT_ACTION_SUCCESS,
            payload: { result: result.data, boardId, id }
        });
    } catch (error) {
        dispatch({ type: actionTypes.EDIT_ACTION_ERROR });
    }
};

export const deleteAction = (boardId, id) => async dispatch => {
    dispatch({
        type: actionTypes.DELETE_ACTION_START
    });

    try {
        const result = await client.delete(`/boards/${boardId}/actions/${id}`);
        dispatch({
            type: actionTypes.DELETE_ACTION_SUCCESS,
            payload: { boardId, id }
        });
        return result.data;
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_ACTION_ERROR });
    }
};

export const updateActionSocket = (action, boardId) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_ACTION_SOCKET,
        payload: { action, boardId }
    });
};

export const deleteActionSocket = (action, boardId) => async dispatch => {
    dispatch({
        type: actionTypes.DELETE_ACTION_SOCKET,
        payload: { action, boardId }
    });
};
