import * as React from 'react';

function SvgCancel(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 19 19"
            width="1em"
            {...props}
        >
            <path
                clipRule="evenodd"
                d="M.7 17.3a.99.99 0 101.4 1.4l7.45-7.45L17 18.7a.99.99 0 101.4-1.4l-6.845-6.845a1.067 1.067 0 010-1.51L18.401 2.1A.99.99 0 1017 .7L9.55 8.15 2.1.7A.99.99 0 00.7 2.1l6.845 6.845a1.067 1.067 0 010 1.51z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}

export default SvgCancel;
