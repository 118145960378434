import React from 'react';
import styled from '@emotion/styled/macro';

import { offset } from '../../components/themes/grid';
import {
    white,
    mainYellow,
    darkGrey,
    shadow
} from '../../components/themes/colors';
import { mainFont } from '../../components/themes/typography';
import { tablet } from '../../components/themes/custom-media';

import Header from '../../components/header/Header';

import SvgBackgroundShape from '../../components/assets/SvgBackgroundShape';
import SvgHeroImage from '../../components/assets/SvgHeroImage';

const StyledLink = styled.a`
    ${offset(1, 12)}
    padding: 0.5rem 5rem;
    color: ${darkGrey};
    font-size: 2.8rem;
    text-decoration: none;
    background-color: ${mainYellow};
    border-radius: 1rem;
    box-shadow: 0 0.4rem 0.4rem 0 ${shadow};

    &:hover {
        color: ${white};
        background-color: ${darkGrey};
    }

    ${tablet} {
        margin-top: 1.5rem;
        font-size: 2.2rem;
    }
`;

const Main = styled.main`
    margin-top: 5rem;
    margin-right: 2.4rem;
    margin-left: 2.4rem;
`;

const Text = styled.p`
    ${offset(1, 12)}
    margin-top: 8.6rem;
    color: ${darkGrey};
    font-size: 2.8rem;
    font-family: ${mainFont};

    ${tablet} {
        font-size: 2.2rem;
    }
`;

const Text2 = styled.p`
    ${offset(1, 12)}
    color: ${darkGrey};
    font-size: 3.6rem;
    font-family: ${mainFont};

    ${tablet} {
        font-size: 3rem;
    }
`;

const BackgroundImg = styled(SvgBackgroundShape)`
    position: fixed;
    right: 0rem;
    bottom: 0rem;
    z-index: -1;
`;

const HeroImg = styled(SvgHeroImage)`
    position: fixed;
    right: 0rem;
    bottom: 0rem;
    z-index: -1;
`;

function HomePage() {
    return (
        <>
            <Header />
            <Main>
                <BackgroundImg />
                <HeroImg />
                <Text>Hi! Are you ready to retrospective into you?</Text>
                <Text2>So let’s go...</Text2>
                <StyledLink href="/auth/google">Start</StyledLink>
            </Main>
        </>
    );
}

export default HomePage;
