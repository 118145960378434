import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { actions } from '../../state/board';

import Header from '../../components/header/Header';
import SideBar from '../../components/sidebar/SideBar';
import SetupBar from '../../components/setupBar/SetupBar';

import secure from '../../components/secure/Secure';

function BasePage({ children }) {
    const { id } = useParams();
    const location = useLocation();
    const isBoardPage = location.pathname.includes('/boards');

    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(actions.loadBoardById(id));
        } else {
            dispatch(actions.loadBoards());
        }
    }, [dispatch, id]);

    return (
        <>
            <Header>{isBoardPage && <SetupBar />}</Header>
            <SideBar />
            {children}
        </>
    );
}

BasePage.propTypes = {
    children: PropTypes.node
};

export default secure(BasePage);
