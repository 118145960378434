import * as React from 'react';

function SvgUnlockSetupBar(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 31 39"
            width="0.79em"
            {...props}
        >
            <path
                d="M15.7 4.324a5.745 5.745 0 015.737 5.738h3.825c0-5.273-4.29-9.563-9.563-9.563s-9.563 4.29-9.563 9.563v3.825H4.224a3.829 3.829 0 00-3.826 3.825v17.214a3.829 3.829 0 003.826 3.825h22.95A3.829 3.829 0 0031 34.926V17.712a3.829 3.829 0 00-3.825-3.825H9.96v-3.825A5.745 5.745 0 0115.7 4.324zm11.479 30.602h-9.567v-4.357c1.138-.664 1.912-1.884 1.912-3.294 0-2.11-1.715-3.825-3.825-3.825s-3.825 1.716-3.825 3.825c0 1.408.775 2.63 1.913 3.294v4.357H4.224V17.712h22.95z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgUnlockSetupBar;
