import React from 'react';
import styled from '@emotion/styled/macro';

import { white, mainYellow, darkGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';

import SvgMrLogo from '../assets/SvgMrLogo';

const StyledDiv = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: 9.4rem;
    padding: 0.5rem;
    background-color: ${white};
    border-bottom: 0.4rem solid ${mainYellow};
`;

const StyledLoading = styled.p`
    margin: 0;
    color: ${darkGrey};
    font-weight: bold;
    font-size: 5rem;
    font-family: ${mainFont};
`;

const StyledAppLogo = styled(SvgMrLogo)`
    font-size: 6.8rem;
`;

function Loading(props) {
    return (
        <StyledDiv {...props}>
            <StyledAppLogo />
            <StyledLoading>Loading....</StyledLoading>
        </StyledDiv>
    );
}

export default Loading;
