import * as React from 'react';

function SvgLockSetupBar(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 31 39"
            width="0.79em"
            {...props}
        >
            <path
                d="M15.7.499c-5.274 0-9.564 4.29-9.564 9.563v3.825H4.224a3.829 3.829 0 00-3.826 3.825v17.214a3.829 3.829 0 003.826 3.825h22.95A3.829 3.829 0 0031 34.926V17.712a3.829 3.829 0 00-3.825-3.825h-1.913v-3.825c0-5.273-4.29-9.563-9.563-9.563zM9.96 10.062A5.745 5.745 0 0115.7 4.324a5.745 5.745 0 015.738 5.738v3.825H9.961zM27.18 34.926h-9.567v-4.357c1.138-.664 1.912-1.884 1.912-3.294 0-2.11-1.715-3.825-3.825-3.825s-3.825 1.716-3.825 3.825c0 1.408.775 2.63 1.913 3.294v4.357H4.224V17.712h22.95z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgLockSetupBar;
