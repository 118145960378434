import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { selectors } from '../../state/account';

import { white, mainYellow, darkGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';

import SvgMrLogo from '../assets/SvgMrLogo';

const StyledHeader = styled.header`
    position: fixed;
    top: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 9.4rem;
    padding: 0.5rem;
    font-size: ${({ large }) => (large ? '5rem' : '3.6rem')};
    background-color: ${({ large }) => (large ? 'transparent' : white)};
    border-bottom: ${({ large }) =>
        large ? 'none' : `0.4rem solid ${mainYellow}`};
`;

const StyledAppName = styled.p`
    margin: 0;
    color: ${darkGrey};
    font-weight: bold;
    font-family: ${mainFont};
`;

const UserName = styled.p`
    margin: 0;
    color: ${darkGrey};
    font-weight: bold;
    font-size: 2.3rem;
    font-family: ${mainFont};
`;

const StyledLink = styled(Link)`
    font-size: 6.8rem;
    text-decoration: none;
`;

const Header = ({ children }) => {
    const name = useSelector(selectors.getDisplayName);

    const location = useLocation();

    const isHomePage = location.pathname === '/';

    return (
        <StyledHeader large={isHomePage}>
            <StyledLink title="Home" to="/home" aria-label="Go to homepage">
                <SvgMrLogo />
            </StyledLink>
            <div>
                <StyledAppName>Retrolution</StyledAppName>
                {!isHomePage && <UserName>Hi! {name}</UserName>}
            </div>
            {children}
        </StyledHeader>
    );
};

Header.propTypes = {
    children: PropTypes.node
};

export default Header;
