import React from 'react';
import styled from '@emotion/styled/macro';

import { offset } from '../../components/themes/grid';
import { darkGrey } from '../../components/themes/colors';
import { mainFont } from '../../components/themes/typography';
import { tablet } from '../../components/themes/custom-media';

import Header from '../../components/header/Header';

import SvgBackgroundShape from '../../components/assets/SvgBackgroundShape';
import SvgHeroImageNotFound from '../../components/assets/SvgHeroImageNotFound';

const Main = styled.main`
    margin-top: 10rem;
    margin-right: 2.4rem;
    margin-left: 2.4rem;
`;

const Text = styled.p`
    ${offset(1, 12)}
    color: ${darkGrey};
    font-size: 2.8rem;
    font-family: ${mainFont};

    ${tablet} {
        font-size: 2.2rem;
    }
`;

const BackgroundImg = styled(SvgBackgroundShape)`
    position: fixed;
    right: 0rem;
    bottom: 0rem;
    z-index: -1;
`;

const HeroImgNotFound = styled(SvgHeroImageNotFound)`
    position: fixed;
    right: 0rem;
    bottom: 0rem;
    z-index: -1;
`;

const StyledLink = styled.a`
    color: ${darkGrey};
    font-weight: bold;
    text-decoration: none;
`;

function HomePage() {
    return (
        <>
            <Header />
            <Main>
                <BackgroundImg />
                <HeroImgNotFound />
                <Text>Oh No... Page not Found!</Text>
                <Text>
                    Please back <StyledLink href="/">home</StyledLink>!
                </Text>
            </Main>
        </>
    );
}

export default HomePage;
