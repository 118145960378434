import * as React from 'react';

function SvgHome(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 32 30"
            width="1.06em"
            {...props}
        >
            <path
                d="M31.338 14.754L16.798.33a1.128 1.128 0 00-1.23-.244 1.129 1.129 0 00-.365.244L.663 14.754A2.24 2.24 0 000 16.342a2.253 2.253 0 002.26 2.243H3.79v10.293c0 .62.505 1.122 1.13 1.122h8.82v-5.874a1.977 1.977 0 013.954 0V30h9.386c.625 0 1.13-.501 1.13-1.121V18.585h1.531c.6 0 1.176-.235 1.6-.659a2.234 2.234 0 00-.004-3.172z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgHome;
