export const LOAD_BOARD_START = 'BOARD/LOAD_BOARD_START';
export const LOAD_BOARD_SUCCESS = 'BOARD/LOAD_BOARD_SUCCESS';
export const LOAD_BOARD_ERROR = 'BOARD/LOAD_BOARD_ERROR';

export const LOAD_BOARD_ID_START = 'BOARD/LOAD_BOARD_ID_START';
export const LOAD_BOARD_ID_SUCCESS = 'BOARD/LOAD_BOARD_ID_SUCCESS';
export const LOAD_BOARD_ID_ERROR = 'BOARD/LOAD_BOARD_ID_ERROR';

export const CREATE_BOARD_START = 'BOARD/CREATE_BOARD_START';
export const CREATE_BOARD_SUCCESS = 'BOARD/CREATE_BOARD_SUCCESS';
export const CREATE_BOARD_ERROR = 'BOARD/CREATE_BOARD_ERROR';

export const EDIT_BOARD_START = 'BOARD/EDIT_BOARD_START';
export const EDIT_BOARD_SUCCESS = 'BOARD/EDIT_BOARD_SUCCESS';
export const EDIT_BOARD_ERROR = 'BOARD/EDIT_BOARD_ERROR';

export const DELETE_BOARD_START = 'BOARD/DELETE_BOARD_START';
export const DELETE_BOARD_SUCCESS = 'BOARD/DELETE_BOARD_SUCCESS';
export const DELETE_BOARD_ERROR = 'BOARD/DELETE_BOARD_ERROR';

export const CREATE_CARD_START = 'BOARD/CREATE_CARD_START';
export const CREATE_CARD_SUCCESS = 'BOARD/CREATE_CARD_SUCCESS';
export const CREATE_CARD_ERROR = 'BOARD/CREATE_CARD_ERROR';

export const EDIT_CARD_START = 'BOARD/EDIT_CARD_START';
export const EDIT_CARD_SUCCESS = 'BOARD/EDIT_CARD_SUCCESS';
export const EDIT_CARD_ERROR = 'BOARD/EDIT_CARD_ERROR';

export const DELETE_CARD_START = 'BOARD/DELETE_CARD_START';
export const DELETE_CARD_SUCCESS = 'BOARD/DELETE_CARD_SUCCESS';
export const DELETE_CARD_ERROR = 'BOARD/DELETE_CARD_ERROR';

export const MERGE_CARD_START = 'BOARD/MERGE_CARD_START';
export const MERGE_CARD_SUCCESS = 'BOARD/MERGE_CARD_SUCCESS';
export const MERGE_CARD_ERROR = 'BOARD/MERGE_CARD_ERROR';

export const UNMERGE_CARD_START = 'BOARD/UNMERGE_CARD_START';
export const UNMERGE_CARD_SUCCESS = 'BOARD/UNMERGE_CARD_SUCCESS';
export const UNMERGE_CARD_ERROR = 'BOARD/UNMERGE_CARD_ERROR';

export const VOTE_GROUP_START = 'BOARD/VOTE_GROUP_START';
export const VOTE_GROUP_SUCCESS = 'BOARD/VOTE_GROUP_SUCCESS';
export const VOTE_GROUP_ERROR = 'BOARD/VOTE_GROUP_ERROR';

export const UNVOTE_GROUP_START = 'BOARD/UNVOTE_GROUP_START';
export const UNVOTE_GROUP_SUCCESS = 'BOARD/UNVOTE_GROUP_SUCCESS';
export const UNVOTE_GROUP_ERROR = 'BOARD/UNVOTE_GROUP_ERROR';

export const LOCK_BOARD_START = 'BOARD/LOCK_BOARD_START';
export const LOCK_BOARD_SUCCESS = 'BOARD/LOCK_BOARD_SUCCESS';
export const LOCK_BOARD_ERROR = 'BOARD/LOCK_BOARD_ERROR';

export const UNLOCK_BOARD_START = 'BOARD/UNLOCK_BOARD_START';
export const UNLOCK_BOARD_SUCCESS = 'BOARD/UNLOCK_BOARD_SUCCESS';
export const UNLOCK_BOARD_ERROR = 'BOARD/UNLOCK_BOARD_ERROR';

export const UPDATE_BOARD_SOCKET = 'BOARD/UPDATE_BOARD_SOCKET';

export const DELETE_BOARD_SOCKET = 'BOARD/DELETE_BOARD_SOCKET';

export const UPDATE_CARD_SOCKET = 'BOARD/UPDATE_CARD_SOCKET';

export const DELETE_CARD_SOCKET = 'BOARD/DELETE_CARD_SOCKET';

export const UPDATE_GROUP_SOCKET = 'BOARD/UPDATE_GROUP_SOCKET';

export const MERGE_CARD_SOCKET = 'BOARD/MERGE_CARD_SOCKET';

export const UNMERGE_CARD_SOCKET = 'BOARD/UNMERGE_CARD_SOCKET';

export const GROUP_VOTES_SOCKET = 'BOARD/GROUP_VOTES_SOCKET';

export const CREATE_ACTION_START = 'BOARD/CREATE_ACTION_START';
export const CREATE_ACTION_SUCCESS = 'BOARD/CREATE_ACTION_SUCCESS';
export const CREATE_ACTION_ERROR = 'BOARD/CREATE_ACTION_ERROR';

export const EDIT_ACTION_START = 'BOARD/EDIT_ACTION_START';
export const EDIT_ACTION_SUCCESS = 'BOARD/EDIT_ACTION_SUCCESS';
export const EDIT_ACTION_ERROR = 'BOARD/EDIT_ACTION_ERROR';

export const DELETE_ACTION_START = 'BOARD/DELETE_ACTION_START';
export const DELETE_ACTION_SUCCESS = 'BOARD/DELETE_ACTION_SUCCESS';
export const DELETE_ACTION_ERROR = 'BOARD/DELETE_ACTION_ERROR';

export const UPDATE_ACTION_SOCKET = 'BOARD/UPDATE_ACTION_SOCKET';

export const DELETE_ACTION_SOCKET = 'BOARD/DELETE_ACTION_SOCKET';
