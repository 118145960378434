import * as actionTypes from './actionTypes';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_PROFILE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
