import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../state/board';
import { selectors } from '../../state/account';

import { mainYellow, white, darkGrey, shadow } from '../themes/colors';
import Button from '../button/Button';
import TextArea from '../textArea/TextArea';
import SvgDelete from '../assets/SvgDelete';
import ConfirmationModal from '../confirmationModal/ConfirmationModal';

const StyledTextArea = styled(TextArea)`
    margin-right: 5rem;
    background-color: transparent;
`;

const StyledCard = styled.article`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: ${darkGrey};
    border-radius: 0.8rem;
`;

const StyledButton = styled(Button)`
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;

    &:hover {
        color: ${mainYellow};
    }

    &:nth-of-type(2) {
        margin-left: auto;
    }
`;

const StyledSplitButton = styled(StyledButton)`
    margin-left: auto;
`;

const ToolBar = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledProfilePic = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    width: 2.7rem;
    height: 2.7rem;
    margin-top: 1rem;
    margin-right: 1rem;
    border: solid 1px ${mainYellow};
    border-radius: 10rem;
    box-shadow: 0 4px 4px 0 ${shadow};
`;

function Card({
    text,
    disabled,
    id,
    published,
    sectionId,
    draftId,
    boardId,
    owner,
    onUnMergeClick,
    merged,
    onRemoveDraft,
    lock,
    status,
    userVotes,
    totalVotes
}) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(text);
    const userId = useSelector(selectors.getUserId);
    const userProfilePic = useSelector(selectors.getProfilePic);
    const displayName = useSelector(selectors.getDisplayName);
    const [cardId, setCardId] = useState();
    const canUnmergeCard =
        status !== 'actions' &&
        status !== 'finished' &&
        merged &&
        totalVotes === 0;

    useEffect(() => {
        setValue(text || '');
    }, [text]);

    return (
        <StyledCard>
            <StyledProfilePic
                src={id ? owner?.profilePic : userProfilePic}
                alt={id ? owner?.owner?.displayName : displayName}
            />

            <StyledTextArea
                autoFocus={!!draftId}
                id={id || draftId}
                minRows="2"
                placeholder="Type here..."
                disabled={disabled}
                readOnly={owner?._id !== userId && !draftId}
                value={value}
                onChange={event => setValue(event.target.value)}
                onBlur={async () => {
                    if (!value) {
                        return;
                    }

                    if (!id) {
                        await dispatch(
                            actions.createCard(boardId, draftId, {
                                sectionId: sectionId,
                                text: value
                            })
                        );
                        onRemoveDraft(draftId);
                    } else if (owner?._id === userId && id) {
                        await dispatch(
                            actions.editCard(boardId, id, {
                                sectionId: sectionId,
                                text: value
                            })
                        );
                    }
                }}
            />

            <ToolBar>
                {(owner?._id === userId || draftId) && !disabled && (
                    <StyledButton
                        aria-label="Delete card"
                        title="Delete Card"
                        onClick={() => {
                            if (id) {
                                setCardId(id);
                            } else {
                                onRemoveDraft(draftId);
                            }
                        }}
                    >
                        <SvgDelete />
                    </StyledButton>
                )}

                {canUnmergeCard && (
                    <StyledSplitButton
                        onClick={() => {
                            onUnMergeClick(id);
                        }}
                    >
                        split
                    </StyledSplitButton>
                )}

                {(owner?._id === userId || draftId) &&
                    !disabled &&
                    !published &&
                    id && (
                        <StyledButton
                            onClick={async () => {
                                await dispatch(
                                    actions.editCard(boardId, id, {
                                        sectionId: sectionId,
                                        publish: true,
                                        publishDate: new Date()
                                    })
                                );
                            }}
                        >
                            publish
                        </StyledButton>
                    )}
            </ToolBar>

            <ConfirmationModal
                show={!!cardId}
                title="Delete Card"
                onConfirm={async () => {
                    await dispatch(actions.deleteCard(boardId, sectionId, id));
                    setCardId();
                }}
                onCancel={() => {
                    setCardId();
                }}
            />
        </StyledCard>
    );
}

Card.propTypes = {
    text: PropTypes.string,
    disabled: PropTypes.bool,
    published: PropTypes.bool,
    id: PropTypes.string,
    draftId: PropTypes.string,
    boardId: PropTypes.string,
    sectionId: PropTypes.string,
    owner: PropTypes.object,
    merged: PropTypes.bool,
    onMergeCheck: PropTypes.func,
    onUnMergeClick: PropTypes.func,
    onMergeClick: PropTypes.func,
    onRemoveDraft: PropTypes.func,
    lock: PropTypes.bool,
    status: PropTypes.string,
    userVotes: PropTypes.number,
    totalVotes: PropTypes.number
};

export default Card;
