import * as React from 'react';

function SvgNext(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 11 19"
            width="0.58em"
            {...props}
        >
            <path
                d="M.7 18.7a.99.99 0 010-1.4l6.845-6.846a1.067 1.067 0 000-1.509L.7 2.1A.99.99 0 112.1.7l8.118 8.118a1.247 1.247 0 010 1.764L2.1 18.7a.99.99 0 01-1.4 0z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgNext;
