import { css } from '@emotion/core';

export default css`
    html {
        /**
         * Font size 10px on default browser settings.
         * It’s used as 62.5% to adapt to changes in the browser’s default font-size.
         */
        font-size: 62.5%;
        font-family: sans-serif;
    }
    body {
        overflow-x: hidden;
    }
    html,
    body {
        height: 100%;
        margin: 0;
        padding: 0;
    }
    body,
    #root {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    #root {
        height: 100%;
        min-height: 100%;
    }
    main {
        flex-grow: 1;
    }
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
`;
