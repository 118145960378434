import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import accountReducer from './account';
import boardReducer from './board';
import openedActionsReducer from './openedActions';

const history = createBrowserHistory();

export default function configureStore(preloadedState) {
    const reducer = combineReducers({
        router: connectRouter(history),
        account: accountReducer,
        board: boardReducer,
        openedActions: openedActionsReducer
    });

    const middlewares = [routerMiddleware(history), thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const store = createStore(reducer, preloadedState, composedEnhancers);

    return store;
}
