import * as React from 'react';

function SvgArrowDown(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 19 11"
            width="1.73em"
            {...props}
        >
            <path
                d="M.7.7a.99.99 0 011.4 0l6.846 6.845a1.067 1.067 0 001.509 0L17.3.7a.99.99 0 111.4 1.4l-8.118 8.118a1.247 1.247 0 01-1.763 0L.7 2.1A.99.99 0 01.7.7z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgArrowDown;
