import React, { forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import { darkGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';

const TextInput = styled.input`
    height: 1.8rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    overflow: hidden;
    color: ${darkGrey};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
    white-space: nowrap;
    text-overflow: ellipsis;
    background: transparent;
    border: none;
    border-bottom: 0.2rem solid ${darkGrey};
`;

const Input = forwardRef(({ ...props }, ref) => {
    return <TextInput ref={ref} {...props} />;
});

Input.displayName = 'Input';

Input.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string
};

export default Input;
