import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import { selectors as accountSelectors } from '../../state/account';

import { mainYellow, lightGrey, darkGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';
import { HEADER_HEIGHT } from '../themes/variables';
import SvgAdd from '../assets/SvgAdd';
import Button from '../button/Button';
import Action from '../action/Action';

const AddCardButton = styled(Button)`
    margin-left: auto;
    padding: 0.5rem;
    font-size: 2rem;

    &:hover {
        color: ${mainYellow};
    }
`;

const StyledActionSection = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
    height: calc(100% - ${HEADER_HEIGHT});
    margin: 9.4rem 0.5rem 0 0.5rem;
    overflow-y: auto;
    background-color: ${lightGrey};
    border-radius: 0.8rem;
`;

const Title = styled.p`
    margin: 0.5rem;
    margin-right: 5rem;
    color: ${darkGrey};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
`;

const ToolsDiv = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0.5rem;
    background: linear-gradient(
        180deg,
        rgba(228, 228, 229, 1) 80%,
        rgba(228, 228, 229, 0) 100%
    );
    border-radius: 0.8rem;
`;

function ActionSection({
    actions,
    sectionId,
    boardId,
    lock,
    boardStatus,
    guests
}) {
    const userId = useSelector(accountSelectors.getUserId);
    const [draftActions, setDraftActions] = useState([]);

    const addDraftAction = () => {
        setDraftActions(prevActions => [
            ...prevActions,
            {
                draftId: uuidv4()
            }
        ]);
    };

    const removeDraftAction = draftId => {
        setDraftActions(prevActions =>
            prevActions.filter(action => action.draftId !== draftId)
        );
    };

    const filteredActions = actions.filter(
        action => !(!action.publish && action.owner?._id !== userId)
    );

    return (
        <StyledActionSection>
            <ToolsDiv>
                <Title>Actions</Title>
                {boardId && boardStatus === 'actions' && (
                    <AddCardButton
                        title="New Card"
                        onClick={addDraftAction}
                        aria-label="Add new card"
                    >
                        <SvgAdd />
                    </AddCardButton>
                )}
            </ToolsDiv>

            {draftActions?.map(draft => (
                <Action
                    key={draft.draftId}
                    boardId={boardId}
                    sectionId={sectionId}
                    onRemoveDraft={removeDraftAction}
                    lock={lock}
                    draftId={draft.draftId}
                    opened={!draft.opened}
                />
            ))}

            {filteredActions?.map(action => (
                <Action
                    key={action._id}
                    lock={lock}
                    boardId={boardId}
                    status={boardStatus}
                    disabled={lock}
                    owner={action.owner}
                    text={action.text}
                    published={action.publish}
                    id={action._id}
                    draftId={action.draftId}
                    guests={guests}
                    taskOwner={action.taskOwner}
                    opened={action?.opened}
                />
            ))}
        </StyledActionSection>
    );
}

ActionSection.propTypes = {
    actions: PropTypes.array,
    sectionId: PropTypes.string,
    boardId: PropTypes.string,
    lock: PropTypes.bool,
    boardStatus: PropTypes.string,
    guests: PropTypes.array
};

export default ActionSection;
