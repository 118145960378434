import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    result: undefined
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_OPENED_ACTIONS_START:
        case actionTypes.DONE_ACTION_START:
            return {
                ...state,
                isLoading: true
            };

        case actionTypes.LOAD_OPENED_ACTIONS_ERROR:
        case actionTypes.DONE_ACTION_ERROR:
            return {
                ...state,
                isLoading: false
            };

        case actionTypes.LOAD_OPENED_ACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                result: action.payload
            };
        }

        case actionTypes.DONE_ACTION_SUCCESS: {
            const board = state.result.find(board =>
                board.actions.some(act => act._id === action.payload.id)
            );

            const filteredBoards = state.result.filter(
                brd => brd.boardId !== board.boardId
            );

            const boardIndex = state.result.indexOf(board);

            const filteredActions = board.actions.filter(
                act => act._id !== action.payload.id
            );

            const editedAction = board.actions.find(
                act => act._id === action.payload.id
            );

            const actionIndex = board.actions.indexOf(editedAction);

            filteredActions.splice(actionIndex, 0, action.payload.result);

            filteredBoards.splice(boardIndex, 0, {
                ...board,
                actions: filteredActions
            });

            return {
                ...state,
                isLoading: false,
                result: filteredBoards
            };
        }

        default:
            return state;
    }
};
