import merge from 'lodash/merge';

import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    result: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_BOARD_START:
        case actionTypes.LOAD_BOARD_ID_START:
        case actionTypes.CREATE_BOARD_START:
        case actionTypes.EDIT_BOARD_START:
        case actionTypes.DELETE_BOARD_START:
        case actionTypes.CREATE_CARD_START:
        case actionTypes.EDIT_CARD_START:
        case actionTypes.DELETE_CARD_START:
        case actionTypes.MERGE_CARD_START:
        case actionTypes.UNMERGE_CARD_START:
        case actionTypes.VOTE_GROUP_START:
        case actionTypes.UNVOTE_GROUP_START:
        case actionTypes.CREATE_ACTION_START:
        case actionTypes.EDIT_ACTION_START:
        case actionTypes.DELETE_ACTION_START:
            return {
                ...state,
                isLoading: true
            };

        case actionTypes.LOAD_BOARD_ERROR:
        case actionTypes.LOAD_BOARD_ID_ERROR:
        case actionTypes.CREATE_BOARD_ERROR:
        case actionTypes.EDIT_BOARD_ERROR:
        case actionTypes.DELETE_BOARD_ERROR:
        case actionTypes.CREATE_CARD_ERROR:
        case actionTypes.EDIT_CARD_ERROR:
        case actionTypes.DELETE_CARD_ERROR:
        case actionTypes.MERGE_CARD_ERROR:
        case actionTypes.UNMERGE_CARD_ERROR:
        case actionTypes.VOTE_GROUP_ERROR:
        case actionTypes.UNVOTE_GROUP_ERROR:
        case actionTypes.CREATE_ACTION_ERROR:
        case actionTypes.EDIT_ACTION_ERROR:
        case actionTypes.DELETE_ACTION_ERROR:
            return {
                ...state,
                isLoading: false
            };

        case actionTypes.LOAD_BOARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                result: action.payload
            };

        case actionTypes.LOAD_BOARD_ID_SUCCESS: {
            const filteredBoards = (state.result || []).filter(
                board => board._id !== action.payload._id
            );
            return {
                ...state,
                isLoading: false,
                result: [...filteredBoards, action.payload]
            };
        }

        case actionTypes.CREATE_BOARD_SUCCESS:
            return {
                ...state,
                result: [...(state.result || []), action.payload],
                isLoading: false
            };

        case actionTypes.EDIT_BOARD_SUCCESS:
        case actionTypes.LOCK_BOARD_SUCCESS:
        case actionTypes.UNLOCK_BOARD_SUCCESS: {
            const boardResult = state.result.find(
                board => board._id === action.payload._id
            );

            const filteredBoards = state.result.filter(
                board => board._id !== action.payload._id
            );

            const newBoard = merge({}, boardResult, action.payload.result);

            return {
                ...state,
                result: [...filteredBoards, newBoard],
                isLoading: false
            };
        }

        case actionTypes.DELETE_BOARD_SUCCESS: {
            const filteredBoards = state.result.filter(
                board => board._id !== action.payload.boardId
            );

            return {
                ...state,
                result: filteredBoards,
                isLoading: false
            };
        }

        case actionTypes.CREATE_CARD_SUCCESS: {
            const boardResult = state.result.find(board =>
                board.sections.some(
                    section => section._id === action.payload.sectionId
                )
            );

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            return {
                ...state,
                result: [...filteredBoards, action.payload.result],
                isLoading: false
            };
        }

        case actionTypes.EDIT_CARD_SUCCESS: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const section = boardResult.sections.find(
                sec => sec._id === action.payload.sectionId
            );

            const sectionIndex = boardResult.sections.indexOf(section);

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            const filteredSections = boardResult.sections.filter(
                sec => sec._id !== action.payload.sectionId
            );

            const editedCard = section.cards.find(
                card => card._id === action.payload.result._id
            );

            const filteredCards = section.cards.filter(
                card => card._id !== action.payload.result._id
            );

            const newSection = {
                ...section,
                cards: [...filteredCards, editedCard].sort((a, b) => {
                    if (a.publishDate && b.publishDate) {
                        return (
                            new Date(b.publishDate) - new Date(a.publishDate)
                        );
                    } else if (!a.publish && !b.publish) {
                        return new Date(a.createdAt) - new Date(b.createdAt);
                    } else {
                        return a.publish - b.publish;
                    }
                })
            };

            filteredSections.splice(sectionIndex, 0, newSection);

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        sections: filteredSections
                    }
                ],
                isLoading: false
            };
        }

        case actionTypes.DELETE_CARD_SUCCESS: {
            const boardResult = state.result.find(board =>
                board.sections.some(
                    section => section._id === action.payload.sectionId
                )
            );

            const section = boardResult.sections.find(
                section => section._id === action.payload.sectionId
            );

            const sectionIndex = boardResult.sections.indexOf(section);

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            const newSections = boardResult.sections.filter(
                section => section._id !== action.payload.sectionId
            );

            const filteredCards = section.cards.filter(
                card => card._id !== action.payload.id
            );

            newSections.splice(sectionIndex, 0, {
                ...section,
                cards: filteredCards
            });

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        allUserVotesInBoard:
                            action.payload.result.allUserVotesInBoard,
                        votedInBoard: action.payload.result.votedInBoard,
                        sections: newSections
                    }
                ],
                isLoading: false
            };
        }

        case actionTypes.MERGE_CARD_SUCCESS:
        case actionTypes.UNMERGE_CARD_SUCCESS: {
            const boardResult = state.result.find(board =>
                board.sections.some(
                    section => section._id === action.payload.sectionId
                )
            );

            const section = boardResult.sections.find(
                section => section._id === action.payload.sectionId
            );

            const sectionIndex = boardResult.sections.indexOf(section);

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            const newSections = boardResult.sections.filter(
                section => section._id !== action.payload.sectionId
            );

            const cards = section.cards.map(card => {
                const newCard = action.payload.result.find(
                    oldCard => oldCard._id === card._id
                );

                if (newCard) {
                    return newCard;
                }

                return card;
            });

            newSections.splice(sectionIndex, 0, {
                ...section,
                cards
            });

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        sections: newSections
                    }
                ],
                isLoading: false
            };
        }

        case actionTypes.VOTE_GROUP_SUCCESS:
        case actionTypes.UNVOTE_GROUP_SUCCESS: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            const newBoard = merge({}, boardResult, action.payload.result);

            return {
                ...state,
                result: [...filteredBoards, newBoard],
                isLoading: false
            };
        }

        case actionTypes.UPDATE_BOARD_SOCKET: {
            const boardResult = state.result.find(
                board => board._id === action.payload.board._id
            );

            const filteredBoards = state.result.filter(
                board => board._id !== action.payload.board._id
            );

            const newBoard = merge({}, boardResult, action.payload.board);

            return {
                ...state,
                result: [...filteredBoards, newBoard]
            };
        }

        case actionTypes.DELETE_BOARD_SOCKET: {
            const filteredBoards = state.result.filter(
                board => board._id !== action.payload.board._id
            );

            return {
                ...state,
                result: filteredBoards
            };
        }

        case actionTypes.UPDATE_CARD_SOCKET: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const section = boardResult.sections.find(
                sec => sec._id === action.payload.data.sectionId
            );

            const sectionIndex = boardResult.sections.indexOf(section);

            const filteredBoards = state.result.filter(
                board => board._id !== action.payload.boardId
            );

            const filteredSections = boardResult.sections.filter(
                sec => sec._id !== section._id
            );

            const editedCard = section.cards.find(
                card => card._id === action.payload.data.card._id
            );

            const editedCardIndex = section.cards.indexOf(editedCard);

            const filteredCards = section.cards.filter(
                card => card._id !== action.payload.data.card._id
            );

            filteredCards.splice(editedCardIndex, 0, action.payload.data.card);

            const newSection = {
                ...section,
                cards: filteredCards.sort((a, b) => {
                    return new Date(b.publishDate) - new Date(a.publishDate);
                })
            };

            filteredSections.splice(sectionIndex, 0, newSection);

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        sections: filteredSections
                    }
                ]
            };
        }

        case actionTypes.DELETE_CARD_SOCKET: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const section = boardResult.sections.find(section =>
                section.cards.some(card => card._id === action.payload.card._id)
            );

            const sectionIndex = boardResult.sections.indexOf(section);

            const filteredBoards = state.result.filter(
                board => board._id !== action.payload.boardId
            );

            const newSections = boardResult.sections.filter(
                sec => sec._id !== section._id
            );

            const filteredCards = section.cards.filter(
                card => card._id !== action.payload.card._id
            );

            newSections.splice(sectionIndex, 0, {
                ...section,
                cards: filteredCards
            });

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        votedInBoard: action.payload.card.votedInBoard,
                        sections: newSections
                    }
                ]
            };
        }

        case actionTypes.UPDATE_GROUP_SOCKET: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const section = boardResult.sections.find(section =>
                section.cards.some(
                    card => card.group._id === action.payload.group._id
                )
            );

            const sectionIndex = boardResult.sections.indexOf(section);

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            const newSections = boardResult.sections.filter(
                sec => sec._id !== section._id
            );

            const cards = section.cards.map(card => {
                if (card.group._id === action.payload.group._id) {
                    return { ...card, group: action.payload.group };
                }
                return card;
            });

            newSections.splice(sectionIndex, 0, {
                ...section,
                cards
            });

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        sections: newSections
                    }
                ]
            };
        }

        case actionTypes.MERGE_CARD_SOCKET:
        case actionTypes.UNMERGE_CARD_SOCKET: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const section = boardResult.sections.find(section =>
                section.cards.some(
                    card => card._id === action.payload.cards[0]._id
                )
            );

            const sectionIndex = boardResult.sections.indexOf(section);

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            const newSections = boardResult.sections.filter(
                sec => sec._id !== section._id
            );

            const cards = section.cards.map(card => {
                const newCard = action.payload.cards.find(
                    oldCard => oldCard._id === card._id
                );

                if (newCard) {
                    return newCard;
                }

                return card;
            });

            newSections.splice(sectionIndex, 0, {
                ...section,
                cards
            });

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        sections: newSections
                    }
                ]
            };
        }

        case actionTypes.GROUP_VOTES_SOCKET: {
            const boardResult = state.result.find(
                board => board._id === action.payload.board._id
            );

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            const newBoard = merge({}, boardResult, action.payload.board);

            return {
                ...state,
                result: [...filteredBoards, newBoard]
            };
        }

        case actionTypes.CREATE_ACTION_SUCCESS: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        actions: [action.payload.result, ...boardResult.actions]
                    }
                ],
                isLoading: false
            };
        }

        case actionTypes.EDIT_ACTION_SUCCESS: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const editedAction = boardResult.actions.find(
                act => act._id === action.payload.id
            );

            const actionIndex = boardResult.actions.indexOf(editedAction);

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            const filteredActions = boardResult.actions.filter(
                act => act._id !== action.payload.id
            );

            filteredActions.splice(actionIndex, 0, action.payload.result);

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        actions: filteredActions.sort((a, b) => {
                            if (a.publishDate && b.publishDate) {
                                return b.publishDate - a.publishDate;
                            } else {
                                return a.publish - b.publish;
                            }
                        })
                    }
                ],
                isLoading: false
            };
        }

        case actionTypes.DELETE_ACTION_SUCCESS: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const filteredBoards = state.result.filter(
                board => board._id !== boardResult._id
            );

            const filteredActions = boardResult.actions.filter(
                act => act._id !== action.payload.id
            );

            return {
                ...state,
                result: [
                    ...filteredBoards,
                    {
                        ...boardResult,
                        actions: filteredActions
                    }
                ],
                isLoading: false
            };
        }

        case actionTypes.UPDATE_ACTION_SOCKET: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const editedAction = boardResult.actions.find(
                act => act._id === action.payload.action._id
            );

            const actionIndex = boardResult.actions.indexOf(editedAction);

            const filteredActions = boardResult.actions.filter(
                act => act._id !== action.payload.action._id
            );

            filteredActions.splice(actionIndex, 0, action.payload.action);

            return {
                ...state,
                result: [
                    {
                        ...boardResult,
                        actions: filteredActions.sort((a, b) => {
                            return a.publishDate - b.publishDate;
                        })
                    }
                ]
            };
        }

        case actionTypes.DELETE_ACTION_SOCKET: {
            const boardResult = state.result.find(
                board => board._id === action.payload.boardId
            );

            const filteredActions = boardResult.actions.filter(
                act => act._id !== action.payload.action._id
            );

            return {
                ...state,
                result: [
                    {
                        ...boardResult,
                        actions: filteredActions
                    }
                ]
            };
        }

        default:
            return state;
    }
};
