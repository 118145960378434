import client from '../../client';

import * as actionTypes from './actionTypes';

export const loadOpenedActions = () => async dispatch => {
    dispatch({
        type: actionTypes.LOAD_OPENED_ACTIONS_START
    });

    try {
        const result = await client.get('/openedactions');
        dispatch({
            type: actionTypes.LOAD_OPENED_ACTIONS_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_OPENED_ACTIONS_ERROR });
    }
};

export const doneAction = id => async dispatch => {
    dispatch({
        type: actionTypes.DONE_ACTION_START
    });

    try {
        const result = await client.put(`/openedactions/${id}/done`);
        dispatch({
            type: actionTypes.DONE_ACTION_SUCCESS,
            payload: { result: result.data, id }
        });
    } catch (error) {
        dispatch({ type: actionTypes.DONE_ACTION_ERROR });
    }
};
