import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    selectors as boardSelectors,
    actions as boardActions
} from '../../state/board';
import { selectors as accountSelectors } from '../../state/account';

import { HEADER_HEIGHT } from '../themes/variables';
import { white, lightGrey, darkGrey, mainYellow } from '../themes/colors';
import { mainFont } from '../themes/typography';
import SvgDelete from '../assets/SvgDelete';
import Button from '../button/Button';
import Loading from '../loading/Loading';
import ConfirmationModal from '../confirmationModal/ConfirmationModal';

import socket from '../../socketClient';

const Main = styled.main`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    margin: calc(${HEADER_HEIGHT} + 0.8rem) 1rem 1rem 11rem;
    overflow-y: auto;
`;

const StyledSection = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 1rem);
    margin: 0.5rem 0.5rem;
    background-color: ${lightGrey};
    border-radius: 0.8rem;
`;

const Title = styled.p`
    position: sticky;
    top: 0;
    width: 100%;
    margin: 1rem 1rem 0rem 0;
    padding: 0 0 1rem 1rem;
    overflow: hidden;
    color: ${darkGrey};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
    white-space: nowrap;
    text-overflow: ellipsis;
    background: linear-gradient(
        180deg,
        rgba(228, 228, 229, 1) 80%,
        rgba(228, 228, 229, 0) 100%
    );

    &:first-letter {
        text-transform: uppercase;
    }
`;

const Body = styled.p`
    margin: 1rem;
    overflow: hidden;
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
    white-space: nowrap;
    text-overflow: ellipsis;
    background: transparent;

    &:first-letter {
        text-transform: uppercase;
    }
`;

const StyledDivBoard = styled.div`
    display: flex;
    flex-direction: row;
    width: calc((100% / 5) - (0.5rem * 2));
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    padding: 0.2rem;
    background-color: ${darkGrey};
    border-radius: 1rem;
`;

const StyledLink = styled(Link)`
    width: 90%;
    text-decoration: none;
`;

const StyledButton = styled(Button)`
    margin-top: auto;
    margin-left: auto;
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;

    &:hover {
        color: ${mainYellow};
    }
`;

const History = () => {
    const dispatch = useDispatch();
    const boards = useSelector(boardSelectors.getBoards);
    const isLoadingBoard = useSelector(boardSelectors.getLoading);
    const userId = useSelector(accountSelectors.getUserId);
    const [boardId, setBoardId] = useState();

    useEffect(() => {
        if (!boards || boards.length === 0) {
            return;
        }

        socket.on(boardId, data => {
            switch (data.type) {
                case 'deleteBoard':
                    dispatch(boardActions.deleteBoardSocket(data.payload));
                    break;

                default:
                    break;
            }
        });
    }, [dispatch, boards, boardId]);

    if (isLoadingBoard || !boards) {
        return <Loading />;
    }

    const boardsByTeam = boards.reduce((teamsSoFar, { team, ...board }) => {
        if (!teamsSoFar[team]) {
            teamsSoFar[team] = {
                name: team,
                boards: [board]
            };
        } else {
            teamsSoFar[team].boards.push(board);
        }

        return teamsSoFar;
    }, {});

    return (
        <Main>
            {Object.values(boardsByTeam).map(team => {
                return (
                    <StyledSection key={team.name}>
                        <Title>{team.name}</Title>
                        {team.boards.map(board => {
                            const date = new Intl.DateTimeFormat('en-GB', {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit'
                            }).format(new Date(board.date));

                            return (
                                <StyledDivBoard key={board._id}>
                                    <StyledLink to={`/boards/${board._id}`}>
                                        <Body>{date}</Body>
                                        <Body>{board.title}</Body>
                                    </StyledLink>
                                    {userId === board.owner._id && (
                                        <StyledButton
                                            title="Delete Board"
                                            aria-label="Delete board"
                                            onClick={() => {
                                                setBoardId(board._id);
                                            }}
                                        >
                                            <SvgDelete />
                                        </StyledButton>
                                    )}
                                </StyledDivBoard>
                            );
                        })}
                    </StyledSection>
                );
            })}
            <ConfirmationModal
                show={!!boardId}
                title={boards?.find(board => board._id === boardId)?.title}
                onConfirm={async () => {
                    await dispatch(boardActions.deleteBoard(boardId));
                    setBoardId();
                }}
                onCancel={() => {
                    setBoardId();
                }}
            />
        </Main>
    );
};

export default History;
