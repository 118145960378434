import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

import { white, middleGrey, transparentGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';

const StyledModal = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 20%;
    background-color: ${transparentGrey};
    backdrop-filter: blur(0.4rem);
`;

const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 50rem;
    height: 15rem;
    margin: auto;
    background-color: ${middleGrey};
    border-radius: 0.8rem;
`;

const Title = styled.p`
    width: 100%;
    margin: 0;
    padding: 1rem;
    overflow: hidden;
    color: ${white};
    font-size: 2.5rem;
    font-family: ${mainFont};
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    background: transparent;

    &:first-letter {
        text-transform: uppercase;
    }
`;

function Modal({ children, title, show }) {
    return (
        show && (
            <StyledModal aria-modal>
                <StyledBody>
                    <Title>{title}</Title>
                    {children}
                </StyledBody>
            </StyledModal>
        )
    );
}

Modal.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    show: PropTypes.bool
};

export default Modal;
