export const white = '#ffffff';

export const mainYellow = '#f7d269';

export const transparentGrey = '#94959840';

export const lightGrey = '#E4E4E5';

export const middleGrey = '#949598';

export const darkGrey = '#58595b';

export const shadow = '#00000040';

export const green = '#91BA8E';

export const seagreen = '#2e8b57';
