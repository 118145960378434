import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { actions as openedActionsActions } from '../../state/openedActions';

import {
    white,
    darkGrey,
    middleGrey,
    mainYellow,
    shadow,
    seagreen
} from '../themes/colors';
import { mainFont } from '../themes/typography';
import Button from '../button/Button';
import TextArea from '../textArea/TextArea';

const StyledAction = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: ${darkGrey};
    border-radius: 0.8rem;
`;

const BackgroundDiv = styled.article`
    width: calc((100% / 5) - (0.5rem * 2));
    margin: 0.5rem;
    padding-top: 1rem;
    background-color: ${({ opened }) => (opened ? middleGrey : seagreen)};
    border-radius: 0.8rem;
`;

const StyledTextArea = styled(TextArea)`
    margin-right: 5rem;
    background-color: transparent;
`;

const StyledProfilePic = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    width: 2.7rem;
    height: 2.7rem;
    margin-top: 1rem;
    margin-right: 1rem;
    border: solid 0.1rem ${mainYellow};
    border-radius: 10rem;
    box-shadow: 0 0.4rem 0.4rem 0 ${shadow};
`;

const StyledOwnerPic = styled.img`
    display: flex;
    flex-direction: row;
    width: 2.7rem;
    height: 2.7rem;
    margin: 0;
    padding: 0;
    border: solid 0.1rem ${mainYellow};
    border-radius: 50%;
`;

const StyledUl = styled.ul`
    display: flex;
    margin: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    padding: 0;
    list-style: none;
`;

const StyledLi = styled.li`
    margin: 0;
    margin-left: 0.2rem;
    padding: 0;
    list-style: none;
`;

const StyledDate = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0.5rem;
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
    text-transform: capitalize;
`;

const ToolBar = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
`;

const StyledDoneButton = styled(Button)`
    margin-left: auto;
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;

    &:hover {
        color: ${mainYellow};
    }
`;

const StyledLink = styled(Link)`
    width: 90%;
    margin: 1rem 1rem 0 1rem;
    overflow: hidden;
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
    white-space: nowrap;
    text-transform: capitalize;
    text-decoration: none;
    text-overflow: ellipsis;
    background: transparent;
`;

const OpenedAction = ({
    boardId,
    boardTitle,
    closedDate,
    createdAt,
    id,
    opened,
    owner,
    taskOwner,
    text
}) => {
    const dispatch = useDispatch();

    const date = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    });

    return (
        <BackgroundDiv key={id} opened={opened}>
            <StyledLink to={`/boards/${boardId}`}>{boardTitle}</StyledLink>
            <StyledAction>
                <StyledProfilePic
                    src={owner.profilePic}
                    alt={owner.displayName}
                />

                <StyledTextArea
                    id={id}
                    minRows="2"
                    disabled
                    readOnly
                    value={text}
                />
                <StyledUl>
                    {taskOwner.map(user => (
                        <StyledLi key={user._id}>
                            <StyledOwnerPic
                                src={user.profilePic}
                                alt={user.displayName}
                            />
                        </StyledLi>
                    ))}
                </StyledUl>
            </StyledAction>
            <ToolBar>
                {opened && createdAt && (
                    <StyledDate>
                        Created at&nbsp;
                        {date.format(new Date(createdAt))}
                    </StyledDate>
                )}
                {!opened && closedDate && (
                    <StyledDate>
                        Closed at&nbsp;
                        {date.format(new Date(closedDate))}
                    </StyledDate>
                )}

                <StyledDoneButton
                    onClick={async () => {
                        await dispatch(openedActionsActions.doneAction(id));
                    }}
                >
                    {opened ? 'close' : 'open'}
                </StyledDoneButton>
            </ToolBar>
        </BackgroundDiv>
    );
};

OpenedAction.propTypes = {
    boardId: PropTypes.string,
    boardTitle: PropTypes.string,
    closedDate: PropTypes.string,
    createdAt: PropTypes.string,
    id: PropTypes.string,
    opened: PropTypes.bool,
    owner: PropTypes.object,
    taskOwner: PropTypes.array,
    text: PropTypes.string
};

export default OpenedAction;
