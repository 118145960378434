import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';

import { actions as boardActions } from '../../state/board';
import { selectors } from '../../state/account';

import {
    mainYellow,
    white,
    lightGrey,
    middleGrey,
    darkGrey,
    shadow,
    seagreen
} from '../themes/colors';
import Button from '../button/Button';
import TextArea from '../textArea/TextArea';
import SvgDelete from '../assets/SvgDelete';
import ConfirmationModal from '../confirmationModal/ConfirmationModal';

const StyledTextArea = styled(TextArea)`
    margin-right: 5rem;
    background-color: transparent;
`;

const StyledAction = styled.article`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: ${darkGrey};
    border-radius: 0.8rem;
`;

const BackgroundDiv = styled.div`
    margin: 0.5rem;
    background-color: ${({ opened }) => (opened ? middleGrey : seagreen)};
    border-radius: 0.8rem;
`;

const StyledButton = styled(Button)`
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;

    &:hover {
        color: ${mainYellow};
    }

    &:nth-of-type(2) {
        margin-left: auto;
    }

    &:only-of-type {
        margin-right: auto;
    }
`;

const ToolBar = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledProfilePic = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    width: 2.7rem;
    height: 2.7rem;
    margin-top: 1rem;
    margin-right: 1rem;
    border: solid 0.1rem ${mainYellow};
    border-radius: 10rem;
    box-shadow: 0 0.4rem 0.4rem 0 ${shadow};
`;

const StyledOwnerPic = styled.img`
    display: flex;
    flex-direction: row;
    width: 2.7rem;
    height: 2.7rem;
    margin: 0;
    padding: 0;
    border: solid 0.1rem ${lightGrey};
    border-radius: 50%;
`;

const StyledUl = styled.ul`
    display: flex;
    margin: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    padding: 0;
    list-style: none;
`;

const StyledLi = styled.li`
    margin: 0;
    margin-right: 0.2rem;
    padding: 0;
    list-style: none;
`;

const StyledOwnerButton = styled(Button)`
    position: relative;
    width: 2.7rem;
    height: 2.7rem;
    margin: 0;
    padding: 0;
    background: none;
    border-radius: 50%;
    appearance: none;

    &:disabled {
        cursor: default;
    }

    &:enabled:hover::before {
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: solid 0.1rem ${mainYellow};
        border-radius: 50%;
        backdrop-filter: blur(0.1rem);
        content: 'X';
    }
`;

const StyledSelect = styled.select`
    position: relative;
    width: 2.7rem;
    height: 2.7rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    color: ${white};
    text-align: center;
    text-align-last: center;
    background-color: ${middleGrey};
    border: 0.1rem solid ${lightGrey};
    border-radius: 50%;
    cursor: pointer;
    appearance: none;

    &:hover {
        color: ${mainYellow};
        border: solid 0.1rem ${mainYellow};
    }
`;

const StyledOption = styled.option`
    text-align: left;
`;

const TaskOwnerDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
`;

function Action({
    boardId,
    disabled,
    draftId,
    guests,
    id,
    lock,
    onRemoveDraft,
    opened,
    owner,
    published,
    sectionId,
    status,
    taskOwner,
    text
}) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(text);
    const userId = useSelector(selectors.getUserId);
    const userProfilePic = useSelector(selectors.getProfilePic);
    const displayName = useSelector(selectors.getDisplayName);
    const [actionId, setActionId] = useState();

    useEffect(() => {
        setValue(text || '');
    }, [text]);

    const filteredGuests = guests?.filter(
        guest => !taskOwner.some(owner => owner._id === guest._id)
    );

    return (
        <BackgroundDiv opened={opened}>
            <StyledAction>
                <StyledProfilePic
                    src={id ? owner?.profilePic : userProfilePic}
                    alt={id ? owner?.owner?.displayName : displayName}
                />

                <StyledTextArea
                    autoFocus={!!draftId}
                    id={id || draftId}
                    minRows="2"
                    placeholder="Type here..."
                    disabled={disabled}
                    readOnly={owner?._id !== userId && !draftId}
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    onBlur={async () => {
                        if (!value) {
                            return;
                        }
                        if (!id) {
                            await dispatch(
                                boardActions.createAction(boardId, draftId, {
                                    text: value
                                })
                            );
                            onRemoveDraft(draftId);
                        } else if (owner?._id === userId && id) {
                            await dispatch(
                                boardActions.editAction(boardId, id, {
                                    text: value
                                })
                            );
                        }
                    }}
                />
                <ToolBar>
                    {(owner?._id === userId || draftId) && !disabled && (
                        <StyledButton
                            aria-label="Delete action"
                            title="Delete Action"
                            onClick={() => {
                                if (id) {
                                    setActionId(id);
                                } else {
                                    onRemoveDraft(draftId);
                                }
                            }}
                        >
                            <SvgDelete />
                        </StyledButton>
                    )}
                    {(owner?._id === userId || draftId) &&
                        !disabled &&
                        !published &&
                        id && (
                            <StyledButton
                                onClick={async () => {
                                    await dispatch(
                                        boardActions.editAction(boardId, id, {
                                            publish: true,
                                            publishDate: new Date()
                                        })
                                    );
                                }}
                            >
                                publish
                            </StyledButton>
                        )}
                    {published && (
                        <TaskOwnerDiv>
                            {taskOwner?.length > 0 && (
                                <StyledUl>
                                    {taskOwner.map(user => (
                                        <StyledLi key={user._id}>
                                            <StyledOwnerButton
                                                disabled={disabled}
                                                readOnly={disabled}
                                                onClick={async () => {
                                                    const filteredTaskOwner = taskOwner?.filter(
                                                        owner =>
                                                            owner._id !==
                                                            user._id
                                                    );

                                                    await dispatch(
                                                        boardActions.editAction(
                                                            boardId,
                                                            id,
                                                            {
                                                                taskOwner: filteredTaskOwner
                                                            }
                                                        )
                                                    );
                                                }}
                                            >
                                                <StyledOwnerPic
                                                    src={user.profilePic}
                                                    alt={user.displayName}
                                                />
                                            </StyledOwnerButton>
                                        </StyledLi>
                                    ))}
                                </StyledUl>
                            )}
                            {filteredGuests?.length > 0 && !disabled && (
                                <StyledSelect
                                    value="+"
                                    onChange={async event => {
                                        const ownerId = event.target.value;

                                        const filteredTaskOwner = taskOwner?.filter(
                                            owner => owner._id !== ownerId
                                        );

                                        await dispatch(
                                            boardActions.editAction(
                                                boardId,
                                                id,
                                                {
                                                    taskOwner: [
                                                        ...filteredTaskOwner,
                                                        ownerId
                                                    ]
                                                }
                                            )
                                        );
                                    }}
                                >
                                    <StyledOption hidden value="">
                                        +
                                    </StyledOption>
                                    {filteredGuests.map(guest => (
                                        <StyledOption
                                            key={guest._id}
                                            value={guest._id}
                                        >
                                            {guest.displayName}
                                        </StyledOption>
                                    ))}
                                </StyledSelect>
                            )}
                        </TaskOwnerDiv>
                    )}
                </ToolBar>
                <ConfirmationModal
                    show={!!actionId}
                    title="Delete Action"
                    onConfirm={async () => {
                        await dispatch(
                            boardActions.deleteAction(boardId, actionId)
                        );
                        setActionId();
                    }}
                    onCancel={() => {
                        setActionId();
                    }}
                />
            </StyledAction>
        </BackgroundDiv>
    );
}

Action.propTypes = {
    boardId: PropTypes.string,
    disabled: PropTypes.bool,
    draftId: PropTypes.string,
    guests: PropTypes.array,
    id: PropTypes.string,
    lock: PropTypes.bool,
    onRemoveDraft: PropTypes.func,
    opened: PropTypes.bool,
    owner: PropTypes.object,
    published: PropTypes.bool,
    sectionId: PropTypes.string,
    status: PropTypes.string,
    taskOwner: PropTypes.array,
    text: PropTypes.string
};

export default Action;
