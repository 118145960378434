import * as React from 'react';

function SvgBackgroundShape(props) {
    return (
        <svg height="100%" viewBox="0 0 1374 1024" fill="none" {...props}>
            <g filter="url(#backgroundShape_svg__filter0_d)">
                <path
                    d="M303.5 782c-89 0-240.5 39-230 242.5h1300V-1h-431C793.5 90 925 217.5 925 295c0 84.5-120.5 166.062-234.5 112C598 363.135 469.902 396.5 429 551.5c-19 72 11.5 230.5-125.5 230.5z"
                    fill="#FFEEBE"
                />
            </g>
            <defs>
                <filter
                    id="backgroundShape_svg__filter0_d"
                    x={0.981}
                    y={-73}
                    width={1444.52}
                    height={1169.5}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={36} />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default SvgBackgroundShape;
