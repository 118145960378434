import styled from '@emotion/styled/macro';

import { white, darkGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';

const Button = styled.button`
    color: ${darkGrey};
    font-family: ${mainFont};
    background: none;
    border: none;
    transition: color 0.1s ease-in;

    &:hover {
        color: ${white};
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export default Button;
