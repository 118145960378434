import React from 'react';
import styled from '@emotion/styled/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { selectors, actions } from '../../state/board';

import Section from '../../components/section/Section';
import ActionSection from '../../components/actionSection/ActionSection';
import Loading from '../../components/loading/Loading';
import { HEADER_HEIGHT } from '../../components/themes/variables';
import socket from '../../socketClient';

const Main = styled.main`
    display: flex;
    flex-direction: row;
    height: calc(100% - ${HEADER_HEIGHT} - 1.8rem);
    margin: 0.8rem 1rem 1rem 11rem;
`;

function Board() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const board = useSelector(state => selectors.getBoardById(state, id));

    useEffect(() => {
        if (!id) {
            return;
        }

        socket.on(id, data => {
            switch (data.type) {
                case 'updateBoard':
                    dispatch(actions.updateBoardSocket(data.payload));
                    break;

                case 'updateCard':
                    dispatch(actions.updateCardSocket(data.payload, id));
                    break;

                case 'updateAction':
                    dispatch(actions.updateActionSocket(data.payload, id));
                    break;

                case 'updateGroup':
                    dispatch(actions.updateGroupSocket(data.payload, id));
                    break;

                case 'votingGroup':
                    dispatch(actions.groupVotesSocket(data.payload));
                    break;

                case 'deleteCard':
                    dispatch(actions.deleteCardSocket(data.payload, id));
                    break;

                case 'deleteAction':
                    dispatch(actions.deleteActionSocket(data.payload, id));
                    break;

                case 'mergeCards':
                    dispatch(actions.mergeCardSocket(data.payload, id));
                    break;

                case 'unmergeCards':
                    dispatch(actions.unmergeCardSocket(data.payload, id));
                    break;

                default:
                    break;
            }
        });
    }, [dispatch, id]);

    if (id && !board) {
        return <Loading />;
    }

    if (!board) {
        return (
            <Main>
                <Section title="To Improve" />
                <Section title="Went Well" />
                <Section title="Actions" />
            </Main>
        );
    }

    //this is for old boards with action section
    const sections = board.sections.filter(sec => sec.title !== 'Actions');

    return (
        <Main>
            {sections.map(section => (
                <Section
                    sectionId={section._id}
                    key={section._id}
                    title={section.title}
                    cards={section.cards}
                    boardId={board._id}
                    lock={board.lock}
                    boardStatus={board.status}
                    numVotes={board.numVotes}
                    allUserVotesInBoard={board.allUserVotesInBoard}
                />
            ))}
            <ActionSection
                actions={board.actions}
                boardId={board._id}
                lock={board.lock}
                boardStatus={board.status}
                guests={board.guests}
            />
        </Main>
    );
}

export default Board;
