import * as React from 'react';

function SvgDelete(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 14 18"
            width="0.78em"
            {...props}
        >
            <path
                d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2zm3.17-7.83a.996.996 0 011.41 0L7 9.59l1.42-1.42a.997.997 0 011.41 1.41L8.41 11l1.42 1.42a.997.997 0 01-1.41 1.41L7 12.41l-1.42 1.42a.997.997 0 11-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 010-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgDelete;
