import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import TextareaAutosize from 'react-textarea-autosize';

import { white, darkGrey } from '../themes/colors';
import { mainFont } from '../themes/typography';

const StyledTextArea = styled(TextareaAutosize)`
    margin: 0.5rem;
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;
    font-family: ${mainFont};
    background-color: ${darkGrey};
    border: none;
    border-radius: 0.8rem;
    resize: none;

    &::placeholder {
        color: ${white};
    }
`;

function TextArea(props) {
    const ref = useRef();

    const handleOnInput = () => {
        const offsetHeight = ref.current.offsetHeight;
        const scrollHeight = ref.current.scrollHeight;
        let currentRows = ref.current.rows;
        const startRows = parseInt(props.rows);

        if (offsetHeight < scrollHeight) {
            ref.current.rows++;
        } else if (!(currentRows <= startRows)) {
            ref.current.rows--;
        }
    };

    return <StyledTextArea {...props} ref={ref} onInput={handleOnInput} />;
}

TextArea.propTypes = {
    rows: PropTypes.string
};

export default TextArea;
