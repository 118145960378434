import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

import { transparentGrey, mainYellow, darkGrey } from '../themes/colors';
import { HEADER_HEIGHT } from '../themes/variables';
import { mainFont } from '../themes/typography';

import SvgHome from '../assets/SvgHome';
import SvgRetro from '../assets/SvgRetro';
import SvgActions from '../assets/SvgActions';
import SvgLogout from '../assets/SvgLogout';
import SvgArrow from '../assets/SvgArrow';

import Button from '../button/Button';

const Aside = styled.aside`
    position: fixed;
    top: ${HEADER_HEIGHT};
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 30rem;
    height: calc(100% - 8.8rem);
    margin-top: 0.8rem;
    background-color: ${transparentGrey};
    transform: translateX(calc(100% / 3 * -2));
    backdrop-filter: blur(0.4rem);
    transition: transform 0.25s linear;

    ${props =>
        props.expanded &&
        css`
            transform: translateX(0);
        `}
`;

const ArrowButton = styled(Button)`
    position: relative;
    margin-top: 0.5rem;
    margin-left: auto;
    font-size: 2rem;

    &:hover {
        color: ${mainYellow};
    }

    & > svg {
        margin-top: 0.2rem;
        transition: transform 0.25s ease-in 0.25s;

        ${props =>
            props.open &&
            css`
                transform: rotateZ(180deg);
            `}
    }
`;

const Span = styled.span`
    margin-left: 2rem;
    font-size: 2.2rem;
    transition: opacity 0.25s ease-in;
    transition-delay: 0s;
`;

const linkStyles = css`
    display: flex;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem 2rem;
    color: ${darkGrey};
    font-weight: bold;
    font-size: 4rem;
    font-family: ${mainFont};
    text-decoration: none;
    background: none;
    border: none;
    transition: color 0.1s ease-in;

    &:hover {
        color: ${mainYellow};
    }

    &:last-of-type {
        margin-top: auto;
        margin-bottom: 2rem;
    }
`;

const StyledLink = styled(Link)`
    ${linkStyles}

    & > svg {
        transform: translateX(calc(30rem / 3 * 2));
        transition: transform 0.25s ease-in;

        ${props =>
            props.open &&
            css`
                transform: translateX(0%);
            `}
    }

    & > ${Span} {
        opacity: 0;

        ${props =>
            props.open &&
            css`
                opacity: 1;
                transition-delay: 0.25s;
            `}
    }
`;

const StyledLogoutLink = styled.a`
    ${linkStyles}

    & > svg {
        transform: translateX(calc(30rem / 3 * 2));
        transition: transform 0.25s ease-in;

        ${props =>
            props.open &&
            css`
                transform: translateX(0%);
            `}
    }

    & > ${Span} {
        opacity: 0;

        ${props =>
            props.open &&
            css`
                opacity: 1;
                transition-delay: 0.25s;
            `}
    }
`;

function SideBar() {
    const [expanded, setExpanded] = useState(false);

    return (
        <Aside expanded={expanded}>
            <ArrowButton
                title={expanded ? 'Collapse sidebar' : 'Expand sidebar'}
                open={expanded}
                onClick={() => setExpanded(!expanded)}
                aria-label={expanded ? 'Collapse sidebar' : 'Expand sidebar'}
            >
                <SvgArrow />
            </ArrowButton>
            <StyledLink
                title="Home"
                to="/home"
                aria-label="Go to homepage"
                open={expanded}
            >
                <SvgHome />
                <Span>Home</Span>
            </StyledLink>
            <StyledLink
                title="New Board"
                to="/boards"
                aria-label="Add new board"
                open={expanded}
            >
                <SvgRetro />
                <Span>Retro...</Span>
            </StyledLink>
            <StyledLink
                title="Opened Actions"
                to="/openedactions"
                aria-label="Opened actions"
                open={expanded}
            >
                <SvgActions />
                <Span>Opened Actions</Span>
            </StyledLink>
            <StyledLogoutLink
                title="Logout"
                open={expanded}
                href="/auth/logout"
                aria-label="Logout"
            >
                <SvgLogout />
                <Span>Logout</Span>
            </StyledLogoutLink>
        </Aside>
    );
}

export default SideBar;
