import * as React from 'react';

function SvgPrevious(props) {
    return (
        <svg
            fill="none"
            height="1em"
            viewBox="0 0 11 19"
            width="0.58em"
            {...props}
        >
            <path
                d="M10.4.7a.99.99 0 010 1.4L3.555 8.946a1.067 1.067 0 000 1.509L10.4 17.3A.99.99 0 119 18.7L.882 10.582a1.247 1.247 0 010-1.763L9 .7a.99.99 0 011.4 0z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgPrevious;
