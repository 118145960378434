import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { actions, selectors } from '../../state/account';

import Loading from '../loading/Loading';

const secure = Component => {
    const SecuredComponent = props => {
        const dispatch = useDispatch();

        const isProfileLoading = useSelector(selectors.isGuest) === undefined;
        const isAuthenticated = useSelector(selectors.isAuthenticated);

        const location = useLocation();
        const history = useHistory();

        useEffect(() => {
            dispatch(actions.loadProfile());
        }, [dispatch]);

        useEffect(() => {
            if (!isAuthenticated && !isProfileLoading) {
                window.location.replace(
                    `/auth/google?returnurl=${encodeURIComponent(
                        location.pathname
                    )}`
                );
            }
        }, [history, isAuthenticated, isProfileLoading, location.pathname]);

        if (isProfileLoading) {
            return <Loading />;
        }

        return <Component {...props} />;
    };

    return SecuredComponent;
};

export default secure;
