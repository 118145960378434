import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

import { white, mainYellow } from '../themes/colors';
import { mainFont } from '../themes/typography';
import Button from '../button/Button';
import Modal from '../modal/Modal';

const StyledButton = styled(Button)`
    margin-top: auto;
    margin-left: auto;
    color: ${white};
    font-weight: 200;
    font-size: 1.8rem;

    &:hover {
        color: ${mainYellow};
    }
`;

const TextModal = styled.span`
    margin: auto;
    color: ${white};
    font-size: 1.8rem;
    font-family: ${mainFont};
`;

const ModalButtonBar = styled.div`
    display: flex;
    flex-direction: row;
    margin: auto;
`;

function ConfirmationModal({ onConfirm, onCancel, ...remainingProps }) {
    return (
        <Modal {...remainingProps}>
            <TextModal>Are you sure you want to delete this?</TextModal>
            <ModalButtonBar>
                <StyledButton onClick={onConfirm}>Confirm</StyledButton>
                <StyledButton onClick={onCancel}>Cancel</StyledButton>
            </ModalButtonBar>
        </Modal>
    );
}

ConfirmationModal.propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};

export default ConfirmationModal;
