import client from '../../client';

import * as actionTypes from './actionTypes';

export const loadProfile = () => async dispatch => {
    const result = await client.get('/account');

    dispatch({
        type: actionTypes.LOAD_PROFILE,
        payload: result.data
    });
};
